import React, { ReactElement } from "react";

import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";
import { ToolbarTool } from "~/core";
import {
    BaseToolbar,
    FieldBoundaryImportLayer,
    FieldsLayer,
    IMapToolOptions,
    ListenerManager,
    MessageDescriptor,
    Polygon,
    SymbolUtils,
    Tool,
    ToolConfig,
} from "@ai360/core";

import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import type MapView from "@arcgis/core/views/MapView";
import { PictureFillSymbol } from "@arcgis/core/symbols";

interface IFieldBoundaryTransferToolsOptions extends IMapToolOptions {
    onToggleFieldBoundaryTransferModal(isOpen: boolean): void;
    onSetFieldBoundaryTransferGeometries(geometries: Polygon[]): void;
    onAddFieldEditGeometry(geometry: Polygon): void;
    onRemoveFieldEditGeometry(geometry: Polygon): void;
}

class FieldBoundaryTransferTools extends BaseToolbar {
    fieldBoundaryTransfer: ToolbarTool;
    readonly onToggleFieldBoundaryTransferModal: (isOpen: boolean) => void;
    readonly onSetFieldBoundaryTransferGeometries: (geometries: Polygon[]) => void;
    readonly onAddFieldEditGeometry: (geometry: Polygon) => void;
    readonly onRemoveFieldEditGeometry: (geometry: Polygon) => void;

    public activeMapTool: Tool;
    public messages: Record<string, MessageDescriptor>;
    public mapListeners: ListenerManager = new ListenerManager();
    public fieldsLayer: FieldsLayer;
    public fieldBoundaryImportLayer: FieldBoundaryImportLayer;

    #layer: GraphicsLayer;
    #fieldBoundaryGeometries: Polygon[] = [];
    #fieldBoundaryTransferGeometries: Polygon[] = [];
    readonly #selectedGeometrySymbol = SymbolUtils.fromJSON(
        ToolConfig.symbols.zones.normal.withDataImage
    ) as PictureFillSymbol;

    constructor(map: MapView, options: IFieldBoundaryTransferToolsOptions) {
        super(map, options);
        this.messages = this.options.messages;
        this.onToggleFieldBoundaryTransferModal = options.onToggleFieldBoundaryTransferModal;
        this.onSetFieldBoundaryTransferGeometries = options.onSetFieldBoundaryTransferGeometries;
        this.onAddFieldEditGeometry = options.onAddFieldEditGeometry;
        this.onRemoveFieldEditGeometry = options.onRemoveFieldEditGeometry;
        this.#layer = this.mapView.map.findLayerById(
            "FIELD_BOUNDARY_TRANSFER_GRAPHICS_LAYER"
        ) as GraphicsLayer;
        if (!this.#layer) {
            this.#layer = new GraphicsLayer({ id: "FIELD_BOUNDARY_TRANSFER_GRAPHICS_LAYER" });
            this.mapView.map.add(this.#layer);
        }
    }

    setFieldBoundaryTransferGeometries(fieldBoundaryPolygons: Polygon[]) {
        this.#fieldBoundaryTransferGeometries = [...fieldBoundaryPolygons];
        this.#updateGraphics();
    }

    setFieldBoundaryGeometries(fieldBoundaryPolygons: Polygon[]) {
        this.#fieldBoundaryGeometries = [...fieldBoundaryPolygons];
        this.#updateGraphics();
    }

    isSelected() {
        return this.fieldBoundaryTransfer && this.fieldBoundaryTransfer.isSelected();
    }

    unselect() {
        if (this.fieldBoundaryTransfer) {
            this.fieldBoundaryTransfer.unselect(true);
        }
        this.#removeMapListeners();
    }

    #forceUpdate() {
        if (this.options.forceUpdate) {
            this.options.forceUpdate();
        }
    }

    reset() {
        return new Promise<void>((resolve) => {
            this.#layer.removeAll();
            resolve();
        });
    }

    setActiveMapTool(activeMapTool) {
        this.activeMapTool = activeMapTool;
    }

    activateToolset() {
        this.#createMapListeners();
        this.#layer.visible = true;
    }

    #createMapListeners() {
        this.#removeMapListeners();

        this.mapListeners.add(
            this.mapView.on("pointer-up", (evt) => {
                const matchingFieldBoundaryGeometry = this.#fieldBoundaryGeometries.findIndex((g) =>
                    geometryEngine.contains(g, this.mapView.toMap(evt))
                );
                const matchingFieldBoundaryTransferGeometry =
                    this.#fieldBoundaryTransferGeometries.findIndex((g) =>
                        geometryEngine.contains(g, this.mapView.toMap(evt))
                    );
                if (
                    matchingFieldBoundaryGeometry === -1 &&
                    matchingFieldBoundaryTransferGeometry === -1
                ) {
                    return;
                }
                const geometry =
                    matchingFieldBoundaryGeometry !== -1
                        ? this.#fieldBoundaryGeometries[matchingFieldBoundaryGeometry]
                        : this.#fieldBoundaryTransferGeometries[
                              matchingFieldBoundaryTransferGeometry
                          ];
                if (matchingFieldBoundaryGeometry !== -1) {
                    this.#fieldBoundaryGeometries.splice(matchingFieldBoundaryGeometry, 1);
                    this.#fieldBoundaryTransferGeometries.push(geometry);
                    this.onRemoveFieldEditGeometry(geometry);
                } else {
                    this.#fieldBoundaryTransferGeometries.splice(
                        matchingFieldBoundaryTransferGeometry,
                        1
                    );
                    this.#fieldBoundaryGeometries.push(geometry);
                    this.onAddFieldEditGeometry(geometry);
                }
                this.onSetFieldBoundaryTransferGeometries([
                    ...this.#fieldBoundaryTransferGeometries,
                ]);
            })
        );
    }

    #removeMapListeners() {
        this.mapListeners.removeAll();
    }

    #updateGraphics() {
        this.#layer.removeAll();
        const newGraphics = this.#fieldBoundaryTransferGeometries.map(
            (g) =>
                new Graphic({
                    geometry: g,
                    symbol: this.#selectedGeometrySymbol,
                })
        );
        this.#layer.addMany(newGraphics);
    }
}

export class FieldBoundaryTransferToolSet extends FieldBoundaryTransferTools {
    getToolbar(): ReactElement {
        const { formatMessage } = this.options;

        return (
            <ToolbarTool
                key="fieldBoundaryTransfer"
                icon={ToolbarIcon(IconType.TRANSFER)}
                label={formatMessage(this.messages.fieldBoundaryTransfer)}
                onToggle={(evt) => {
                    this.onToggleFieldBoundaryTransferModal(evt.selected);
                }}
                ref={(tool) => (this.fieldBoundaryTransfer = tool)}
            />
        );
    }
}
