import { defineMessages } from "react-intl";

export const messages = defineMessages({
    // FIXME: this belongs in "~/actionPanel"
    validationDlgTitle: {
        id: "actionPanel.recEventCommon.validationDlgTitle",
        defaultMessage: "Validation Errors",
    },
    // FIXME: remove this
    validationErrorMsg: {
        id: "recs-events.validationErrorMsg",
        defaultMessage:
            "{errCode, select, " +
            // Event General error codes
            "80 {Cropping Season is required.}" + // ErrorCode.AgEventCropSeasonRequired
            "81 {Start Date and Time is required.}" + // ErrorCode.AgEventStartDatetimeRequired
            "727 {Irrigation Amount is required when a Unit is selected.}" + // ErrorCode.EventIrrigationAmountUnitDependentRequiredIfAmount
            "728 {Duration is required when a Unit is selected.}" + // ErrorCode.EventIrrigationDurationUnitDependentRequired
            "729 {Frequency is required when a Unit is selected.}" + // ErrorCode.EventIrrigationFrequencyUnitDependentRequired
            "730 {Time Since Last is required when a Unit is selected.}" + // ErrorCode.EventIrrigationTimeSinceLastUnitDependentRequired
            "731 {Rainfall Since is required when a Unit is selected.}" + // ErrorCode.EventIrrigationRainfallSinceUnitDependentRequired
            "732 {Installation Depth is required when a Unit is selected.}" + // ErrorCode.EventIrrigationInstallationDepthUnitDependentRequired
            "828 {Estimated Deficit Water Need Unit is required.}" + //ErrorCode.EventIrrigationEstDeficitWaterNeedUnitRequiredIfEstDeficitWaterNeed
            "830 {Estimated Water Need or Reduction Percent For Deficit is required when a Unit is selected.}" + // ErrorCode.EventIrrigationEstWaterNeedUnitDependentRequired
            "831 {Estimated Deficit Water Need is required when a Unit is selected.}" + // ErrorCode.EventIrrigationEstDeficitWaterNeedUnitDependentRequired
            "842 {Planting Depth Unit is required.}" + // ErrorCode.PlantingDepthUnitRequired
            "843 {Planting Depth is required when a Unit is selected.}" + // ErrorCode.PlantingDepthUnitDependentRequired
            "845 {Row Spacing is required when a Unit is selected.}" + // ErrorCode.RowSpacingUnitDependentRequired
            "2083 {Field is required.}" + // ErrorCode.AgEventFieldRequired
            "2647 {A field boundary must be defined.}" + // ErrorCode.FieldBoundaryGuidRequired
            "2330 {An Event with this Type + Date + Name already exists.}" + // ErrorCode.AgEventTypeDateNameDuplicated
            "2380 {A zone must be defined.}" + // ErrorCode.EventAreaListEmpty
            "2383 {This field is missing required spatial data.  Make a boundary edit to this field to resolve this issue.}" + // ErrorCode.EventAreaPolygonNotFound
            "2814 {Field boundary could not be found for the whole field zone.}" + //ErrorCode.FieldBoundaryMissingForWholeFieldZone
            // Tillage event model error codes
            "327 {Tillage event tillage type required.}" + // ErrorCode.EventTillageTillageTypeRequired
            "699 {Tillage Depth Unit is required.}" + // ErrorCode.TillageDepthUnitRequired,
            "736 {Tillage Depth is required when a Unit is selected.}" + // ErrorCode.TillageDepthUnitDependentRequired
            // Analysis Layer error codes
            "2800 {No Yield data exists for the selected area.}" + // ErrorCode.AnalysisNoYieldDataExists
            "2803 {Analysis Layer was not found.}" + // ErrorCode.AnalysisLayerNotFound
            "2804 {AnalysisLayerTypeGuid is required}" + // ErrorCode.AnalysisLayerTypeGuidMissing
            "2805 {Analysis layer name is required}" + // ErrorCode.AnalysisLayerNameIsRequired
            "2806 {Analysis layer name already exists for this field}" + // ErrorCode.AnalysisLayerNameDuplicated
            "2809 {Analysis Layers cannot be the only item selected for the user role.}" + //ErrorCode.UserRoleAnalysisLayersPermissionSelected
            // Analytics
            "2815 {Analytics cannot be the only item selected for the user role.}" + // ErrorCode.UserRoleAnalyticsPermissionSelected
            // Rec General error codes
            "497 {Recommendation name already exists.}" + // ErrorCode.RecommendationNameDuplicated
            "498 {Recommendation zone is required.}" + // ErrorCode.RecAreaRequired
            "510 {Recommendation name is required.}" + // ErrorCode.RecommendationNameRequired
            "518 {Recommendation Type is required.}" + // ErrorCode.RecommendationTypeRequired
            "519 {Recommendation Rec Date is required.}" + // ErrorCode.RecommendationCreatedDateRequired
            "578 {Guaranteed Analysis is required.}" + // Event.GuaranteedAnalysisRequired
            // Equation Rec error codes
            "2813 {The following {count, plural, one {field is} other {fields are}} missing the required Soil Sample Event results needed to create a Rec:}" + //ErrorCode.RecommendationSoilTestRequired
            "2899 {A Rec with this Type + Date + Name already exists.}" +
            // Scouting Event error codes
            "926 {Crop Height Unit is required.}" + // ErrorCode.EventScoutingCropHeightUnitRequired
            "927 {Crop Population Unit is required.}" + // ErrorCode.EventScoutingCropPopulationUnitRequired
            "928 {Observation Count Unit is required.}" + // ErrorCode.EventObservationCountUnitRequired
            "929 {Weed Height Unit is required.}" + // ErrorCode.EventObservationWeedHeightUnitRequired
            "930 {The specified color ramp was not found.}" + // ErrorCode.ColorRampNotFound
            // Unknown error
            "other {Generic Error (code {errCode}).}" +
            "}",
    },
    batchEventsFailed: {
        id: "recsEvents.batchEventsFailed",
        defaultMessage:
            "One or more Events has incomplete detail. Please check the Events and try to Save again.",
    },
    calibrationRollbackWarning: {
        id: "recsEvents.calibrationRollbackWarning",
        defaultMessage:
            "One or more Events selected for Yield Calibration had errors and the calibration attempt was rolled back.",
    },
    fetchSurfacePolygonsFailed: {
        id: "recsEvents.fetchSurfacePolygonsFailed",
        defaultMessage: "Unable to fetch the surface polygons.",
    },
    unexpectedSurfaceClassCount: {
        id: "recsEvents.unexpectedSurfaceClassCount",
        defaultMessage:
            "Surface returned {classCount} classes, but {classBreakCount} class breaks are defined.",
    },
    s3PhotoCopyFailed: {
        id: "recsEvents.s3PhotoCopyFailed",
        defaultMessage: "Unable to copy observation photo.",
    },
    s3PhotoUploadFailed: {
        id: "recsEvents.s3PhotoUploadFailed",
        defaultMessage: "Unable to save observation photo.",
    },
    s3PhotoGeneratePresignedUrlsFailed: {
        id: "recsEvents.s3PhotoGeneratePresignedUrlsFailed",
        defaultMessage: "Error retrieving Scouting photo urls",
    },
});
