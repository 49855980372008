import {
    all,
    call,
    put,
    select,
    take,
    takeLatest,
    fork,
    PutEffect,
    CallEffect,
    SelectEffect,
    takeEvery,
} from "redux-saga/effects";

import { actions as accordionActions } from "~/accordion";
import { setTriggeredPage } from "~/action-panel/components/event-module/actions";
import { actions as picklistActions } from "~/core/picklist";
import { getTheUserGuid, getUser } from "~/login/selectors";
import { mapActions, mapToolsActions } from "~/map";
import {
    setFieldsBackgroundOnly,
    setFieldsBackgroundOnlyBatch,
} from "~/map/components/map-control/actions";
import { showErrors, setMergeableEvents } from "../event-list/actions";
import * as eventListselectors from "../event-list/selectors";
import {
    actions as recsEventsActions,
    models as recsEventsModels,
    selectors as recsEventsSelectors,
    eventsSelectors,
    eventsModels,
} from "~/recs-events";
import * as eventActions from "~/recs-events/events/actions";
import { LayerAPI, PicklistAPI, AgEventAPI, SearchAPI } from "@ai360/core";
import { Toolset, IEventDetails } from "@ai360/core";
import { ToolsetPayload } from "~/map/components/map-tools/components/map-tools.4x";

import * as layerActions from "../../../layer-module/components/layer-list/actions";

import * as eventModuleActions from "../../actions";
import * as eventTissueActions from "../../components/event-info/components/event-sample-tissue/actions";

import { createBatchEventAccordionItems } from "./components/batch-events-list";
import { sampleSoilSaga } from "./components/event-sample-soil/sagas";
import { sampleTissueSaga } from "./components/event-sample-tissue/sagas";
import { scoutingSaga } from "./components/event-scouting/sagas";
import { tillageSaga } from "./tillage-sagas";

import { transformSamplePointFromApi } from "~/recs-events/events/model";

import { MergeableEventType } from "~/data/mergeable-events";

import * as actions from "./actions";
import * as selectors from "./selectors";

import { actions as notificationActions } from "~/notifications";
import { actions as messagingActions } from "~/messaging";
import { BATCH_TEMPLATE_FIELD_GUID } from "~/recs-events/model";

interface IUseEventDetails extends IEventDetails {
    isSamplingEvent: boolean;
    isECDataEvent: boolean;
}

const eventDetailsToFieldToolset = (eventDetails: IEventDetails) => ({
    fieldGuid: eventDetails.fieldGuid,
    fieldBoundaryGuid: eventDetails.fieldBoundaryGuid,
    customerGuid: eventDetails.customerGuid,
});

const getEventToolset = function (eventDetails) {
    switch (true) {
        case eventDetails.isSamplingEvent:
            return Toolset.SAMPLING;
        case eventDetails.isECDataEvent:
            return Toolset.DEFAULT;
        case eventDetails.isScoutingEvent:
            return Toolset.SCOUTING_ZONE_EDIT;
        default:
            return Toolset.ZONE_EDIT;
    }
};

const fetchSystemAttributePicklistRequest = function* (action) {
    const userGuid = yield select(getTheUserGuid);
    const { payload } = action;
    let systemAttributePicklist = [];
    try {
        const picklistValues = yield call(
            PicklistAPI.getUnitPicklistBySystemAttributeAndEventType,
            userGuid,
            payload.systemAttribute,
            payload.eventType
        );
        if (!payload.forceRaw) {
            systemAttributePicklist = picklistValues.map((val) => ({
                label: val.abbreviation,
                value: val.unitGuid,
            }));
        } else {
            systemAttributePicklist = picklistValues;
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
    yield put(
        picklistActions.fetchedPicklistData({
            [payload.model]: systemAttributePicklist,
        })
    );
};

const fetchSystemAttributePicklist = function* (action) {
    const { payload } = action;
    if (Array.isArray(payload)) {
        for (let i = 0; i < payload.length; i++) {
            yield fork(
                fetchSystemAttributePicklistRequest,
                actions.fetchSystemAttributePicklist(payload[i])
            );
        }
    } else {
        yield fetchSystemAttributePicklistRequest(action);
    }
};

const onBatchUpdateProductBlend = function* (action) {
    const { productMix, previousMixGA } = action.payload;
    const { fieldGuidToCurrentAreaId } = yield select(recsEventsSelectors.getZonesState);
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);

    const eventDetailsToUpdate = [];
    let newEventAreaList = [];

    Array.from(fieldGuidToEventDetails.keys()).forEach((fieldGuid: string) => {
        if (fieldGuid === BATCH_TEMPLATE_FIELD_GUID) {
            return;
        }
        const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
        const currentAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);

        const hasProductMixToUpdate = eventDetails.eventAreaList.some((eventArea) =>
            eventArea.agEventList.some((event) =>
                event.agEventModel.productMixList?.some(
                    (eventProductMix) =>
                        eventProductMix.products.length > 1 &&
                        previousMixGA &&
                        ((eventProductMix.guaranteedAnalysis === previousMixGA &&
                            productMix.guaranteedAnalysis !== previousMixGA) ||
                            (eventProductMix.name === productMix.name &&
                                eventArea.eventAreaId !== currentAreaId) ||
                            (eventProductMix.guaranteedAnalysis === productMix.guaranteedAnalysis &&
                                eventProductMix.name !== productMix.name))
                )
            )
        );

        const isPricingUpdate = eventDetails.eventAreaList.some((eventArea) =>
            eventArea.agEventList.some((event) =>
                event.agEventModel.productMixList?.some(
                    (eventProductMix) =>
                        eventProductMix.guaranteedAnalysis === previousMixGA &&
                        productMix.guaranteedAnalysis === previousMixGA &&
                        eventProductMix.products.some((rp) => {
                            const matchingProduct = productMix.products.find(
                                (p) => p.productGuid === rp.productGuid
                            );
                            return (
                                matchingProduct.cost !== rp.cost ||
                                matchingProduct.costUnitGuid !== rp.costUnitGuid
                            );
                        })
                )
            )
        );

        if (!hasProductMixToUpdate && !isPricingUpdate) {
            return;
        } else if (isPricingUpdate) {
            newEventAreaList = eventDetails.eventAreaList.map((eventArea) => {
                const newEventList = eventArea.agEventList.map((event) => {
                    const newEventProductMixList = event.agEventModel.productMixList?.map(
                        (eventProductMix) => {
                            if (eventProductMix.guaranteedAnalysis !== previousMixGA) {
                                return eventProductMix;
                            } else {
                                const newProductsList = eventProductMix.products.map((rpmp) => {
                                    const matchingProduct = productMix.products.find(
                                        (p) => p.productGuid === rpmp.productGuid
                                    );
                                    return {
                                        ...rpmp,
                                        cost: matchingProduct.cost,
                                        costUnitGuid: matchingProduct.costUnitGuid,
                                        costUnit: matchingProduct.costUnit,
                                    };
                                });
                                return {
                                    ...eventProductMix,
                                    products: newProductsList,
                                    targetCost: productMix.targetCost,
                                    targetCostUnitGuid: productMix.targetCostUnitGuid,
                                };
                            }
                        }
                    );
                    return eventsModels.AgEvent.updateAgEvent(event, {
                        agEventModel: event.agEventModel.updateAgEventModel({
                            productMixList: newEventProductMixList,
                        }),
                    });
                });
                return eventsModels.AgEventArea.updateAgEventArea(eventArea, {
                    agEventList: newEventList,
                });
            });
        } else {
            newEventAreaList = eventDetails.eventAreaList.map((eventArea) => {
                const newEventList = eventArea.agEventList.map((event) => {
                    const newEventProductMixList = event.agEventModel.productMixList?.map(
                        (eventProductMix) => {
                            const shouldOverwrite =
                                (eventProductMix.guaranteedAnalysis === previousMixGA ||
                                    eventProductMix.name === productMix.name) &&
                                eventArea.eventAreaId !== currentAreaId &&
                                productMix.guaranteedAnalysis !== previousMixGA;

                            const shouldUpdateName =
                                eventProductMix.guaranteedAnalysis === previousMixGA &&
                                eventProductMix.name !== productMix.name;

                            if (!shouldOverwrite && !shouldUpdateName) {
                                return eventProductMix;
                            } else if (!shouldOverwrite && shouldUpdateName) {
                                return {
                                    ...eventProductMix,
                                    name: productMix.name,
                                };
                            } else {
                                return {
                                    ...productMix,
                                    agEventGuid: eventProductMix.agEventGuid,
                                    productMixGuid: eventProductMix.productMixGuid,
                                    products: productMix.products.map((p) => ({
                                        ...p,
                                        productMixProductGuid: null,
                                    })),
                                    nutrients: productMix.nutrients.map((p) => ({
                                        ...p,
                                        productMixNutrientGuid: null,
                                    })),
                                };
                            }
                        }
                    );
                    return eventsModels.AgEvent.updateAgEvent(event, {
                        agEventModel: event.agEventModel.updateAgEventModel({
                            productMixList: newEventProductMixList,
                        }),
                    });
                });
                return eventsModels.AgEventArea.updateAgEventArea(eventArea, {
                    agEventList: newEventList,
                });
            });
        }

        eventDetailsToUpdate.push(
            put(
                recsEventsActions.updateEventDetails(fieldGuid, { eventAreaList: newEventAreaList })
            )
        );
    });

    if (eventDetailsToUpdate.length > 0) {
        yield all(eventDetailsToUpdate);
    }
};

const onCloseEventInfo = function* () {
    const accordionState = yield select(selectors.getAccordionState);
    const layerAccordionState = yield select(selectors.getLayerAccordionState);
    const fieldGuidToEventListMap = yield select(eventsSelectors.getFieldGuidToEventListMap);
    yield put(actions.setEventSummary(null));
    yield put(actions.setEventFilterData(null));
    yield put(eventTissueActions.setCropGrowthStageGuid(null));
    yield put(accordionActions.removeAllAccordionItems(accordionState.accordionId));
    yield put(accordionActions.removeAllAccordionItems(layerAccordionState.accordionId));
    yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
    yield put(mapActions.setIsLoading(false, true));
    yield put(eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_LIST));
    yield put(recsEventsActions.deleteTemporaryScoutingPhotos());
    yield put(recsEventsActions.clearEventDetails());
    yield put(notificationActions.clearToasterMessages());
    yield put(layerActions.removeVisibleSurfaces([...fieldGuidToEventListMap.keys()]));
    yield put(setFieldsBackgroundOnly(false));
    yield put(setFieldsBackgroundOnlyBatch(false));
};

const onCopyBatchTemplateToEvents = function* () {
    yield put(actions.setEventDetailsLoading(true));
    const resultAction = yield take([
        recsEventsActions.SAVE_EVENT_DETAILS_FAILED,
        recsEventsActions.INITIALIZE_EVENTS_ZONES_SUCCEEDED,
    ]);
    if (resultAction.type === recsEventsActions.INITIALIZE_EVENTS_ZONES_SUCCEEDED) {
        yield put(recsEventsActions.clearSaveEventDetailsErrorCodeList());
    }
    yield put(actions.setEventDetailsLoading(false));
};

const onCopyEventInfo = function* (action) {
    const { eventSummary } = action.payload;
    const { agEventGeneralGuid } = eventSummary;
    yield put(actions.setEventSummary(eventSummary.updateImportedStatus(0)));
    yield put(actions.setEventDetailsLoading(true));
    yield put(
        eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
    );
    yield put(recsEventsActions.copyEventDetails(agEventGeneralGuid));
};

const onCreateNewClassifiedEvent = function* () {
    yield put(actions.setEventDetailsLoading(true));
    yield put(
        eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
    );
    yield take(recsEventsActions.CREATE_NEW_EVENT_DETAILS);
    yield put(actions.setEventDetailsLoading(false));
};

const onCreateCopyBatchEventDetails = function* () {
    const fieldGuidToEventDetails: Immutable.OrderedMap<string, eventsModels.EventDetails> =
        (yield select(eventsSelectors.getModuleState)).fieldGuidToEventDetails;

    console.assert(fieldGuidToEventDetails.size >= 1);
    if (fieldGuidToEventDetails.size > 1) {
        const userGuid = yield select(getTheUserGuid);
        const fieldGuids = [...fieldGuidToEventDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
        );
        const fields: SearchAPI.IFieldResult[] = yield SearchAPI.getFields({
            userGuid,
            fieldGuid: fieldGuids,
        });

        yield put(
            recsEventsActions.setCurrentBatchFieldGuid(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID)
        );
        const { accordionId } = yield select(selectors.getAccordionState);
        yield put(
            accordionActions.addAccordionItemArray(
                accordionId,
                createBatchEventAccordionItems(fields)
            )
        );
        yield put(
            eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
        );
        return;
    }

    const eventDetails = fieldGuidToEventDetails.values().next().value;

    const eventSummary = recsEventsModels.AgEventSummary.fromEventDetails(eventDetails);

    yield put(actions.setEventSummary(eventSummary));
    yield put(
        eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
    );

    const field = eventDetailsToFieldToolset(eventDetails);
    const toolsetPayload: ToolsetPayload = {
        recEventDetails: eventDetails,
        field,
    };
    if (eventDetails.isSamplingEvent) {
        toolsetPayload.samplePoints = [];
    }
    const toolset = getEventToolset(eventDetails);
    yield put(mapToolsActions.setActiveToolset(toolset, toolsetPayload));
};

const onCreateNewEventDetails = function* () {
    const fieldGuidToEventDetails: Immutable.OrderedMap<string, eventsModels.EventDetails> =
        (yield select(eventsSelectors.getModuleState)).fieldGuidToEventDetails;
    console.assert(fieldGuidToEventDetails.size >= 1);

    const userGuid = yield select(getTheUserGuid);
    const fieldGuids = [...fieldGuidToEventDetails.keys()].filter(
        (fieldGuid) => fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
    );
    const fields: SearchAPI.IFieldResult[] = yield SearchAPI.getFields({
        userGuid,
        fieldGuid: fieldGuids,
    });

    if (fieldGuidToEventDetails.size > 1) {
        yield put(
            recsEventsActions.setCurrentBatchFieldGuid(recsEventsModels.BATCH_TEMPLATE_FIELD_GUID)
        );
        const { accordionId } = yield select(selectors.getAccordionState);
        yield put(
            accordionActions.addAccordionItemArray(
                accordionId,
                createBatchEventAccordionItems(fields)
            )
        );
        yield put(
            eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
        );
        //TODO: Enable when Batch Sampling Manual is available
        //const [fieldGuid, eventDetails] = fieldGuidToEventDetails.entries().next().value;
        // if (eventDetails.isSamplingEvent) {
        //     const toolsetPayload = {
        //         field: eventDetailsToFieldToolset(eventDetails),
        //         recEventDetails: eventDetails,
        //         samplePoints: []
        //     };
        //     yield put(mapToolsActions.setActiveToolset(Toolset.SAMPLING_AUTO, toolsetPayload));
        // }
        return;
    }

    const eventDetails = fieldGuidToEventDetails.values().next().value;

    const field = fields[0];

    const eventSummary = recsEventsModels.AgEventSummary.fromEventDetails(
        eventDetails,
        field.boundaryId,
        field.name,
        field.farmName,
        field.acres,
        field.customerName
    );

    yield put(actions.setEventSummary(eventSummary));
    yield put(
        eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
    );
    const toolsetPayload: ToolsetPayload = {
        recEventDetails: eventDetails,
        field: eventDetailsToFieldToolset(eventDetails),
    };
    if (eventDetails.isSamplingEvent) {
        toolsetPayload.samplePoints = [];
        const layers = yield call(LayerAPI.getAvailableLayersByFieldGuid, userGuid, fieldGuids[0]);
        yield put(layerActions.setLayerInfo(fieldGuids[0], layers));
    }
    const toolset = getEventToolset(eventDetails);
    yield put(mapToolsActions.setActiveToolset(toolset, toolsetPayload));
};

const onFetchEventDetailsSucceeded = function* (action) {
    const { isCopyAction } = action.payload;
    const eventInfo = yield select(selectors.getModuleState);
    const { fieldGuid } = eventInfo.eventSummary;
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const eventDetails: IUseEventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const { mergeableEvents } = yield select(eventListselectors.getModuleState);
    const isMergeEvent = Boolean(mergeableEvents);
    const field = eventDetailsToFieldToolset(eventDetails);
    const toolsetPayload: ToolsetPayload = {
        recEventDetails: eventDetails,
        field,
    };
    // The lengths of the lists below are not checked by the JSON schema validator
    if (
        eventDetails.isSamplingEvent &&
        eventDetails.eventAreaList.length > 0 &&
        eventDetails.eventAreaList[0].agEventList.length > 0
    ) {
        const sampleAgEvent = eventDetails.eventAreaList[0].agEventList[0].agEventModel;
        toolsetPayload.samplePoints = sampleAgEvent.samplePoints;
    }
    if (eventDetails.isECDataEvent || eventDetails.isSamplingEvent) {
        yield put(actions.setECDataLoading(true));
        const userGuid = yield select(getTheUserGuid);
        const layers = yield call(
            LayerAPI.getAvailableLayersByFieldGuid,
            userGuid,
            field.fieldGuid
        );
        yield put(layerActions.setLayerInfo(field.fieldGuid, layers));
        yield put(actions.setECDataLoading(false));
    }
    if (isMergeEvent) {
        toolsetPayload.isMerge = true;
        if (mergeableEvents.type === MergeableEventType.Soil) {
            const userGuid = yield select(getTheUserGuid);
            const sendMergeableEvents = mergeableEvents.all.map((event, index) => {
                const newEvent = { ...event };
                newEvent.order = index + 1;
                return newEvent;
            });
            const response = yield call(AgEventAPI.getMergeAgEvents, userGuid, sendMergeableEvents);
            for (const point of response) {
                transformSamplePointFromApi(point);
            }
            yield put(recsEventsActions.setEventSamplePoints(response));
            toolsetPayload.samplePoints = response;
        }
    }
    const toolset = getEventToolset(eventDetails);
    yield put(mapToolsActions.setActiveToolset(toolset, toolsetPayload));
    if (
        eventInfo.eventSummary.isImportedYn &&
        !eventDetails.isSamplingEvent &&
        !eventDetails.isFromEquationRec
    ) {
        const { filters } = eventDetails;
        yield put(actions.fetchEventFilterSummary(eventDetails.agEventGeneralGuid, filters));
    } else {
        if (!isCopyAction) {
            yield put(actions.setEventDetailsLoading(false));
        }
    }
};

export const onFetchEventFilterSummary = function* (
    action: ReturnType<typeof actions.fetchEventFilterSummary>
): Generator<CallEffect | PutEffect<any>, void, any> {
    const { agEventGeneralGuid, filters } = action.payload;
    let filterSummary;
    try {
        const response = yield call(AgEventAPI.getFilterSummary, agEventGeneralGuid);
        filterSummary = recsEventsModels.AgEventFilterSummary.fromJsonObj(response);
        yield put(actions.setEventFilterData({ ...filterSummary, filters }));
        yield put(actions.setEventDetailsLoading(false));
    } catch (err) {
        // Set the filter data and display error notification
        filterSummary = recsEventsModels.AgEventFilterSummary.fromJsonObj(err.apiResultObj.model);
        yield put(actions.setEventFilterData({ ...filterSummary, filters }));
        yield put(actions.setEventDetailsLoading(false));
        const skip_columns = ["DryRate", "LiquidRate", "SeedingRate"];
        if (
            filterSummary.stats &&
            filterSummary.stats.length > 0 &&
            skip_columns.includes(filterSummary.stats[0].column)
        ) {
            return;
        }
        yield put(notificationActions.apiCallError(err, action));
    }
};

const onSaveEventInfo = function* () {
    yield put(actions.setEventDetailsLoading(true));
    yield put(recsEventsActions.saveEventDetails());
    const resultAction = yield take([
        recsEventsActions.SAVE_EVENT_DETAILS_FAILED,
        recsEventsActions.SAVE_EVENT_DETAILS_SUCCEEDED,
    ]);
    if (resultAction.type === recsEventsActions.SAVE_EVENT_DETAILS_SUCCEEDED) {
        yield put(setTriggeredPage(null));
        yield put(recsEventsActions.clearTemporaryScoutingPhotoList());
        yield put(actions.closeEventInfo());
    }
    yield put(actions.setEventDetailsLoading(false));
};

const onSetCurrentBatchFieldGuid = function* (action) {
    const { batchFieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);

    if (!fieldGuidToEventDetails.has(batchFieldGuid)) {
        return;
    }

    const isBatchTemplate = batchFieldGuid === recsEventsModels.BATCH_TEMPLATE_FIELD_GUID;

    const eventDetails = fieldGuidToEventDetails.get(batchFieldGuid);
    const userGuid = yield select(getTheUserGuid);
    const fields: SearchAPI.IFieldResult[] = isBatchTemplate
        ? []
        : yield SearchAPI.getFields({
              userGuid,
              fieldGuid: [eventDetails.fieldGuid],
          });

    const fieldInfo = isBatchTemplate ? ({} as SearchAPI.IFieldResult) : fields[0];

    const eventSummary = recsEventsModels.AgEventSummary.fromEventDetails(
        eventDetails,
        fieldInfo.boundaryId,
        fieldInfo.name,
        fieldInfo.farmName,
        fieldInfo.acres,
        fieldInfo.customerName
    );
    yield put(actions.setEventSummary(eventSummary));
    yield put(eventActions.setLastEventBatchFieldGuid(batchFieldGuid));

    if (isBatchTemplate) {
        const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== recsEventsModels.BATCH_TEMPLATE_FIELD_GUID
        );
        yield put(mapActions.setZoomToFieldList(fieldGuidList));
        if (!eventDetails.isSamplingEvent) {
            yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
        }
        return;
    }

    const field = eventDetailsToFieldToolset(eventDetails);

    if (eventDetails.isSamplingEvent) {
        //single edit of sample points on one of the events in batch
        const samplePoints = eventDetails.eventAreaList[0].agEventList[0].agEventModel.samplePoints;
        const toolsetPayload = {
            recEventDetails: eventDetails,
            samplePoints,
            field,
            isMerge: false,
        };
        yield put(mapToolsActions.setActiveToolset(Toolset.SAMPLING, toolsetPayload));
        return;
    }
    const toolsetPayload = { recEventDetails: eventDetails, field };
    yield put(mapToolsActions.setActiveToolset(Toolset.ZONE_EDIT, toolsetPayload));
};

const onSetEventAreaIsIncluded = function* (action) {
    const { fieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    if (eventDetails) {
        const field = eventDetailsToFieldToolset(eventDetails);
        const firstArea = eventDetails.eventAreaList[0];
        const firstEvent = !firstArea ? null : firstArea.agEventList[0];
        const samplePoints = !firstEvent ? [] : firstEvent.agEventModel.samplePoints;
        if (samplePoints != null) {
            yield put(
                mapToolsActions.setActiveToolsetPayloadOnly({
                    recEventDetails: eventDetails,
                    field,
                    samplePoints,
                })
            );
        }
    }
};

const onSetEventZonesFromLayer = function* (action) {
    yield put(actions.setEventDetailsLoading(true));
    yield take(recsEventsActions.UPDATE_EVENT_DETAILS);
    yield put(actions.setEventDetailsLoading(false));

    const { fieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const field = eventDetailsToFieldToolset(eventDetails);
    yield put(
        mapToolsActions.setActiveToolsetPayloadOnly({
            recEventDetails: eventDetails,
            field,
        })
    );
};

const onSetShowFilterPoints = function* (action) {
    const { showFilterPoints, fieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    if (showFilterPoints) {
        yield put(layerActions.removeVisibleSurfaces([fieldGuid]));
        yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
    } else {
        if (eventDetails && !eventDetails.isECDataEvent) {
            const field = eventDetailsToFieldToolset(eventDetails);
            const toolsetPayload = { recEventDetails: eventDetails, field };
            yield put(mapToolsActions.setActiveToolset(Toolset.ZONE_EDIT, toolsetPayload));
        }
    }
};

const onShowEventInfo = function* (action) {
    const { eventSummary } = action.payload;
    const { agEventGeneralGuid } = eventSummary;

    yield put(actions.setEventSummary(eventSummary));
    yield put(actions.setEventDetailsLoading(true));
    yield put(
        eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
    );

    yield put(recsEventsActions.fetchEventDetails(agEventGeneralGuid));
    yield put(setFieldsBackgroundOnly(true));
};

export const onRemoveScoutingEvent = function* (
    action: ReturnType<typeof eventActions.removeAggregateScoutingEvent>
): Generator<PutEffect<any> | SelectEffect, void, any> {
    const { fieldGuid, agEventTransactionTypeGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(eventsSelectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const field = eventDetailsToFieldToolset(eventDetails);

    const toolsetPayload = {
        field,
        recEventDetails: eventDetails,
    };
    yield put(mapToolsActions.setActiveToolset(Toolset.ZONE_EDIT, toolsetPayload));

    yield put(recsEventsActions.removeAggregateEvent(fieldGuid, agEventTransactionTypeGuid));
};

export const onUpdateEventSummary = function* (
    action: ReturnType<typeof eventActions.setEventModel>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid } = action.payload;
    const { fieldGuidToEventDetails, soilSampleResults } = yield select(
        eventsSelectors.getModuleState
    );
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);

    if (action.type === recsEventsActions.SET_EVENT_MODEL) {
        const lastBatchFieldGuid = yield select(eventsSelectors.getLastEventBatchFieldGuid);
        if (
            eventDetails.isSamplingEvent &&
            fieldGuidToEventDetails.size > 1 &&
            lastBatchFieldGuid === "BATCH_TEMPLATE"
        ) {
            //When in Batch, only allow event summary updates when individual Events are being adjusted
            return;
        }
        if (!eventDetails.isSamplingEvent || soilSampleResults != null) {
            return;
        }
        const updatePropNames = new Set(Object.keys(action.payload.newProps));
        if (!updatePropNames.has("samplePoints") && !updatePropNames.has("eventId")) {
            return;
        }
    } else if (
        action.type === recsEventsActions.ADD_AGGREGATE_EVENT &&
        eventDetails.isSamplingEvent
    ) {
        const field = eventDetailsToFieldToolset(eventDetails);
        const userInfo = yield select(getUser);
        const { zoneInterpolation, zoneSampling } = userInfo.role;
        const interpolationTypeId = zoneInterpolation
            ? recsEventsModels.InterpolationType.ZONE_INTERPOLATION
            : zoneSampling
            ? recsEventsModels.InterpolationType.ZONE_SAMPLING
            : recsEventsModels.InterpolationType.STANDARD;

        const eventAreaList = eventDetails.eventAreaList.map((eventArea) => {
            return recsEventsModels.AgEventArea.updateAgEventArea(eventArea, {
                agEventList: eventArea.agEventList.map((agEvent) => {
                    return recsEventsModels.AgEvent.updateAgEvent(agEvent, {
                        agEventModel: agEvent.agEventModel.updateAgEventModel({
                            interpolationTypeId,
                        }),
                    });
                }),
            });
        });
        yield put(recsEventsActions.updateEventDetails(fieldGuid, { eventAreaList }));
        const updatedEventDetails = recsEventsModels.EventDetails.updateEventDetails(eventDetails, {
            eventAreaList,
        });
        const toolsetPayload = {
            field,
            recEventDetails: updatedEventDetails,
            samplePoints: [],
        };
        yield put(mapToolsActions.setActiveToolset(Toolset.SAMPLING, toolsetPayload));
    } else if (
        action.type === recsEventsActions.ADD_AGGREGATE_EVENT &&
        eventDetails.isScoutingEvent
    ) {
        const field = eventDetailsToFieldToolset(eventDetails);
        const toolsetPayload = {
            field,
            recEventDetails: eventDetails,
        };
        yield put(mapToolsActions.setActiveToolset(Toolset.SCOUTING_ZONE_EDIT, toolsetPayload));
    }

    const previousEventSummary = yield select(selectors.getEventSummary);
    const eventSummary = recsEventsModels.AgEventSummary.fromEventDetails(
        eventDetails,
        previousEventSummary?.fieldBoundaryGuid,
        previousEventSummary?.fieldName,
        previousEventSummary?.farmName,
        previousEventSummary?.fieldAcres,
        previousEventSummary?.customerName
    );
    yield put(actions.setEventSummary(eventSummary));
};

const onMergeEventsPreview = function* (action) {
    yield put(
        eventModuleActions.setActivePage(eventModuleActions.EventModulePages.EVENT_INFORMATION)
    );

    yield put(actions.setEventDetailsLoading(true));
    const { primaryEventSummary } = action.payload;
    yield put(actions.showEventInfo(primaryEventSummary));
};

const onMergeEvents = function* (action) {
    const { mergeableEvents } = action.payload;
    yield put(actions.setEventDetailsLoading(true));
    yield put(recsEventsActions.mergeEventsSave(mergeableEvents));
};

const onMergeEventsIntoNewStartSuccess = function* (action) {
    const { mergeableEvents } = action.payload;
    yield put(setMergeableEvents(null));
    yield put(actions.addInFlightCollapsingEvents(mergeableEvents.collapsing));
};

const onMergeEventsIntoNewFinishSuccess = function* (mergingGuids) {
    yield put(actions.removeInFlightCollapsingEvents(mergingGuids));
    yield put(eventActions.fetchSelectedFieldEvents());
};

const onMergeEventsIntoNewFinishError = function* (mergingGuids, errorCodes) {
    const toReinstate = (yield select(selectors.getInFlightCollapsingEvents)).filter(
        (eventSummary) => mergingGuids.includes(eventSummary.agEventGeneralGuid)
    );

    const toRemove = toReinstate.map((eventSummary) => eventSummary.agEventGeneralGuid);
    yield put(actions.removeInFlightCollapsingEvents(toRemove));
    yield put(eventActions.fetchSelectedFieldEvents());
    yield put(showErrors(errorCodes));
};

const messageSubscriptions = function* () {
    yield put(
        messagingActions.subscribe(
            0,
            {
                eventName: "mergeEventsIntoNewSuccess",
                generator: function* (message) {
                    yield onMergeEventsIntoNewFinishSuccess(message.agEventGeneralGuids);
                },
            },
            {
                eventName: "mergeEventsIntoNewError",
                generator: function* (message) {
                    yield onMergeEventsIntoNewFinishError(
                        message.agEventGeneralGuids,
                        message.errorCodes
                    );
                },
            }
        )
    );
};

export const eventInfoSaga = function* (): any {
    const eventSummaryUpdateActions = [
        recsEventsActions.ADD_AGGREGATE_EVENT,
        recsEventsActions.REMOVE_AGGREGATE_EVENT,
        recsEventsActions.SET_EVENT_MODEL,
    ];

    yield all([
        sampleSoilSaga(),
        sampleTissueSaga(),
        scoutingSaga(),
        takeEvery(actions.BATCH_UPDATE_PRODUCT_BLEND, onBatchUpdateProductBlend),
        takeLatest(actions.CLOSE_EVENT_INFO, onCloseEventInfo),
        takeLatest(actions.COPY_EVENT_INFO, onCopyEventInfo),
        takeLatest(eventSummaryUpdateActions, onUpdateEventSummary),
        takeLatest(recsEventsActions.REMOVE_AGGREGATE_SCOUTING_EVENT, onRemoveScoutingEvent),
        takeLatest(
            recsEventsActions.CREATE_NEW_CLASSIFIED_EVENT_DETAILS,
            onCreateNewClassifiedEvent
        ),
        takeLatest(recsEventsActions.COPY_BATCH_TEMPLATE_TO_EVENTS, onCopyBatchTemplateToEvents),
        takeLatest(
            recsEventsActions.CREATE_COPY_BATCH_EVENT_DETAILS,
            onCreateCopyBatchEventDetails
        ),
        takeLatest(recsEventsActions.CREATE_NEW_EVENT_DETAILS, onCreateNewEventDetails),
        takeLatest(actions.FETCH_EVENT_FILTER_SUMMARY, onFetchEventFilterSummary),
        takeLatest(recsEventsActions.FETCH_EVENT_DETAILS_SUCCEEDED, onFetchEventDetailsSucceeded),
        takeLatest(actions.SAVE_EVENT_INFO, onSaveEventInfo),
        takeLatest(recsEventsActions.SET_CURRENT_BATCH_FIELD_GUID, onSetCurrentBatchFieldGuid),
        takeLatest(recsEventsActions.SET_EVENT_AREA_IS_INCLUDED, onSetEventAreaIsIncluded),
        takeLatest(recsEventsActions.SET_EVENT_ZONES_FROM_LAYER, onSetEventZonesFromLayer),
        takeLatest(actions.SHOW_EVENT_INFO, onShowEventInfo),
        takeLatest(actions.SET_SHOW_FILTER_POINTS, onSetShowFilterPoints),
        takeLatest(actions.FETCH_SYSTEM_ATTRIBUTE_PICKLIST, fetchSystemAttributePicklist),
        takeLatest(actions.PREVIEW_MERGE_EVENTS, onMergeEventsPreview),
        takeLatest(actions.MERGE_EVENTS, onMergeEvents),
        takeLatest(recsEventsActions.MERGE_EVENTS_SUCCEEDED, onMergeEventsIntoNewStartSuccess),
        tillageSaga(),
        messageSubscriptions(),
    ]);
};
