import { Toolset } from "@ai360/core";
import * as actions from "./actions";
import { Set } from "immutable";
import { IActionData, IPayloadData } from "./interfaces";

export enum CounterAction {
    Increment = "Increment",
    Decrement = "Decrement",
}

export enum ProcessGroup {
    Events = "Events",
    Exports = "Exports",
    Fields = "Fields",
    Imports = "Imports",
    Recs = "Recs",
    Layers = "Layers",
    Reports = "Reports",
}

const initialState = {
    activeMapTool: null,
    activeToolset: Toolset.DEFAULT,
    areaUnits: [
        {
            fullName: "Acres",
            name: "ac",
        },
    ],
    batchReset: false,
    bufferOptions: {
        source: "boundary",
        unit: "feet",
    },
    bufferReady: false,
    currentProcessCounts: new Map<string, Set<string>>(
        Object.getOwnPropertyNames(ProcessGroup).map((prop) => [prop, Set<string>()])
    ),
    currentUnmatchedDecrements: new Map<string, Set<string>>(
        Object.getOwnPropertyNames(ProcessGroup).map((prop) => [prop, Set<string>()])
    ),
    currentFieldEdits: {
        changed: false,
        geometries: [],
        loaded: false,
    },
    drawToolFlags: {
        canClip: false,
        canClipPending: false,
    },
    isProcessing: false,
    lengthUnits: [
        {
            fullName: "Miles",
            name: "mi",
        },
    ],
    gridRemoveFlag: false,
    toolsetDisabled: false,
    toolsetPayload: {},
    traceFlags: {
        apply: false,
        reset: false,
    },
    visibleToolbarMenuDimensions: null,
};

const updateProcessAndDecrementSets = (
    processSet: Set<string>,
    unmatchedDecrementsSet: Set<string>,
    counterAction: string,
    keys: string[]
): {
    processSet: Set<string>;
    unmatchedDecrementsSet: Set<string>;
} => {
    const keysSet = Set<string>(keys.map((k) => k.toLowerCase()));
    switch (counterAction) {
        case CounterAction.Increment: {
            const filteredNewKeys = keysSet.subtract(unmatchedDecrementsSet);
            return {
                processSet: processSet.union(filteredNewKeys),
                unmatchedDecrementsSet: unmatchedDecrementsSet.subtract(keys),
            };
        }
        case CounterAction.Decrement: {
            const newUnmatchedDecrements = keysSet.subtract(processSet);
            return {
                processSet: processSet.subtract(keys),
                unmatchedDecrementsSet: unmatchedDecrementsSet.union(newUnmatchedDecrements),
            };
        }
        default:
            console.error(`Unknown counter action: ${counterAction}`);
    }

    return {
        processSet,
        unmatchedDecrementsSet,
    };
};

export function mapToolsReducer(state = initialState, action: IActionData): any {
    switch (action.type) {
        case actions.PROCESS_COUNTER_MESSAGE: {
            let processCounts = state.currentProcessCounts;
            let unmatchedDecrements = state.currentUnmatchedDecrements;
            for (const message of action.payload as IPayloadData[]) {
                const set = processCounts.get(message.processGroup);
                if (set == null) {
                    console.error(`Unknown process group: ${message.processGroup}`);
                    continue;
                }
                const unmatchedDecrementsSet = unmatchedDecrements.get(message.processGroup);
                const newSets = updateProcessAndDecrementSets(
                    set,
                    unmatchedDecrementsSet,
                    message.action,
                    message.keyList
                );
                processCounts = processCounts.set(message.processGroup, newSets.processSet);
                unmatchedDecrements = unmatchedDecrements.set(
                    message.processGroup,
                    newSets.unmatchedDecrementsSet
                );
            }

            return {
                ...state,
                currentProcessCounts: processCounts,
                currentUnmatchedDecrements: unmatchedDecrements,
            };
        }
        case actions.APPLY_TRACE:
        case actions.RESET_TRACE:
            return {
                ...state,
                traceFlags: {
                    ...state.traceFlags,
                    ...action.flags,
                },
            };
        case actions.SET_BATCH_RESET:
            return {
                ...state,
                batchReset: action.batchReset,
            };
        case actions.SET_BUFFER_OPTIONS:
            return {
                ...state,
                bufferOptions: {
                    ...state.bufferOptions,
                    ...action.options,
                },
            };
        case actions.SET_BUFFER_READY:
            return {
                ...state,
                bufferReady: action.ready,
            };
        case actions.CLEAR_TRACE_FLAGS:
            return {
                ...state,
                traceFlags: {
                    ...initialState.traceFlags,
                },
            };
        case actions.SET_ACTIVE_MAP_TOOL:
            return {
                ...state,
                activeMapTool: action.activeMapTool,
            };
        case actions.SET_ACTIVE_TOOLSET:
            return {
                ...state,
                activeToolset: action.activeToolset,
                toolsetDisabled: action.disabled,
                toolsetPayload: action.payload,
            };
        case actions.SET_ACTIVE_TOOLSET_ONLY:
            return {
                ...state,
                activeToolset: action.activeToolset,
                toolsetDisabled: action.disabled,
            };
        case actions.SET_ACTIVE_TOOLSET_PAYLOAD_ONLY:
            return {
                ...state,
                toolsetPayload: action.payload,
            };
        case actions.SET_CAN_CLIP:
        case actions.SET_CAN_CLIP_PENDING: {
            const { canClip, canClipPending } = action;
            return {
                ...state,
                drawToolFlags: {
                    canClip: canClip != null ? canClip : state.drawToolFlags.canClip,
                    canClipPending:
                        canClipPending != null
                            ? canClipPending
                            : state.drawToolFlags.canClipPending,
                },
            };
        }
        case actions.SET_FIELD_EDIT_GEOMETRIES:
        case actions.SET_FIELD_EDITS_CHANGED:
        case actions.SET_FIELD_EDITS_LOADED: {
            const currentFieldEdits = {
                ...state.currentFieldEdits,
                ...action.payload,
            };
            currentFieldEdits.geometries = [...currentFieldEdits.geometries];
            return {
                ...state,
                currentFieldEdits,
            };
        }
        case actions.SET_REMOVE_GRID_FLAG:
            return {
                ...state,
                gridRemoveFlag: action.gridRemoveFlag,
            };
        case actions.SET_TOOLS_PROCESSING:
            return Object.freeze({
                ...state,
                isProcessing: action.isProcessing,
            });
        case actions.SET_TOOLSET_DISABLED:
            return {
                ...state,
                toolsetDisabled: action.disabled,
            };
        case actions.SET_VISIBLE_TOOLBAR_MENU_DIMENSIONS:
            return {
                ...state,
                visibleToolbarMenuDimensions: action.dimensions,
            };
        // FIXME: use ~/core/units
        case actions.UNITS_FETCH_FAILED:
            console.error("Failed to get area and length units for user.");
            return state;
        case actions.UNITS_FETCH_SUCCEEDED:
            return {
                ...state,
                areaUnits: action.areaUnits,
                lengthUnits: action.lengthUnits,
            };
        default:
            return state;
    }
}
