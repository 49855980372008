import { defineMessages } from "react-intl";

export default defineMessages({
    cancel: { id: "fieldBoundaryTransfer.cancel", defaultMessage: "Cancel" },
    existingField: {
        id: "fieldBoundaryTransfer.existingField",
        defaultMessage: "Existing Field",
    },
    existingFieldTransferDescription: {
        id: "fieldBoundaryTransfer.existingFieldTransferDescription",
        defaultMessage: "Select the boundary part(s) you would like to transfer to another field.",
    },
    farmFieldName: {
        id: "fieldBoundaryTransfer.farmFieldName",
        defaultMessage: "Farm-Field Name",
    },
    farmName: {
        id: "fieldBoundaryTransfer.farmName",
        defaultMessage: "Farm Name",
    },
    fieldName: {
        id: "fieldBoundaryTransfer.fieldName",
        defaultMessage: "Field Name",
    },
    fieldNameExists: {
        id: "fieldBoundaryTransfer.fieldNameExists",
        defaultMessage: "Field name already exists",
    },
    newField: {
        id: "fieldBoundaryTransfer.newField",
        defaultMessage: "New Field",
    },
    newFieldTransferDescription: {
        id: "fieldBoundaryTransfer.newFieldTransferDescription",
        defaultMessage: "Select the boundary part(s) you would like to transfer to new field.",
    },
    title: {
        id: "fieldBoundaryTransfer.title",
        defaultMessage: "Transfer Boundary Part To",
    },
    transfer: {
        id: "fieldBoundaryTransfer.transfer",
        defaultMessage: "Transfer",
    },
});
