import React, { Component, KeyboardEvent, MouseEvent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { List, ListProps } from "react-virtualized";
import classnames from "classnames";
import { debounce, DebouncedFunc, isEqual } from "lodash";
import ResizeObserver from "resize-observer-polyfill";
import onClickOutside from "react-onclickoutside";

import { AppHelpers } from "@ai360/core";
import { TextInput } from "../text-input/text-input";
import { messages } from "../../i18n-messages";
import { DownArrowIcon } from "../../icons";

import "./select-input.css";

import { injectIntl } from "react-intl";
import { hasOnlyInActiveOptions } from "../../../admin/utils";

import {
    IDefaultArrowRendererProps,
    IDefaultNoOptionRendererProps,
    IOptionRendererProps,
    ISelectInputProps,
    ISelectInputState,
    ISelectOption,
} from "./model";

export const optionPropType = PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    value: PropTypes.any,
});

function getScrollParent(element: Element): Element {
    const style = getComputedStyle(element);
    if (style.position === "fixed") {
        return document.body;
    }

    const excludeStaticParent = style.position === "absolute";
    const overflowRegex = /(auto|scroll)/;
    for (let parent = element; parent != null; parent = parent.parentElement) {
        const style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent;
        }
    }

    return document.body;
}

function defaultArrowRenderer({ onMouseDown }: IDefaultArrowRendererProps): JSX.Element {
    return (
        <span onMouseDown={onMouseDown}>
            <DownArrowIcon />
        </span>
    );
}

export function defaultOptionRenderer<T>({
    option,
    isHeader,
    isSelected,
    isHighlighted,
    matchPos,
    matchLen,
    titleValue,
}: IOptionRendererProps<T>): JSX.Element {
    const className = classnames("select-form-input-option", {
        header: isHeader,
        selected: isSelected,
        "filter-match": isHighlighted,
    });

    if (isHeader) {
        return (
            <div className={className}>
                <span>{option.label}</span>
            </div>
        );
    }

    const noFilter = matchPos === -1;
    const preMatch =
        noFilter || typeof option.label !== "string"
            ? option.label
            : option.label.slice(0, matchPos);
    const match =
        noFilter || typeof option.label !== "string"
            ? ""
            : option.label.slice(matchPos, matchPos + matchLen);
    const postMatch =
        noFilter || typeof option.label !== "string" ? "" : option.label.slice(matchPos + matchLen);

    return (
        <div className={className} title={titleValue ? titleValue : (option.label as string)}>
            <span className="pre-match">{preMatch}</span>
            <span className="match">{match}</span>
            <span className="post-match">{postMatch}</span>
        </div>
    );
}

function DefaultNoOptionRenderer_({ intl, typedVal }: IDefaultNoOptionRendererProps) {
    const { formatMessage } = intl;
    return (
        <span className="default-option">
            {formatMessage(messages.noResultsMatch, { typedVal })}
        </span>
    );
}

const DefaultNoOptionRenderer = injectIntl(DefaultNoOptionRenderer_);

export class SelectInput_<T> extends Component<ISelectInputProps<T>, ISelectInputState> {
    private _textInputNode: HTMLInputElement;
    private _debouncedHandleInputResize: DebouncedFunc<() => void>;
    private _resizeObserver: ResizeObserver;
    public textInput: TextInput;
    private listRef: List;
    private containerRef: HTMLDivElement;

    static defaultProps = {
        allowEmptyOptions: false,
        arrowRenderer: defaultArrowRenderer,
        clearable: true,
        clearFilterInputOnBlur: true,
        clearOnEmptyFilterStr: false,
        clearOnFocus: false,
        containerClassNames: [],
        disabled: false,
        errorText: "",
        filterable: true,
        hasError: false,
        headerOptionHeight: 25,
        horizontalMenuAlign: false,
        initialFilterStr: "",
        inputContainerLeftElements: [],
        maxHeight: 250,
        minOptionsWidth: 15,
        noOptionsRenderer: (props: IDefaultNoOptionRendererProps): JSX.Element =>
            (<DefaultNoOptionRenderer {...props} />) as JSX.Element,
        onBlur: (): void => {
            /* */
        },
        onChange: (): void => {
            /* */
        },
        onFocus: (): void => {
            /* */
        },
        onInputChange: (): void => {
            /* */
        },
        openOnFocus: true,
        optionHeight: 25,
        optionRenderer: defaultOptionRenderer,
        options: [],
        placeholderText: "",
        required: false,
        showTopLabel: true,
        value: undefined,
        valueKey: "value",
        visible: true,
    };

    #filterOptions(
        filterVal: string,
        options: ISelectOption<T>[],
        isHeaderOption: (opt: ISelectOption<T>) => boolean
    ): (number | Map<number, number> | number[] | null)[] {
        const matchingIdxList: number[] = [];
        const optionMatchPositions: number[] = [];
        const filteredOptListIdxMap: Map<number, number> = new Map();

        filterVal = filterVal == null ? "" : filterVal.toLowerCase();
        for (let optionIdx = 0, matchCount = 0; optionIdx < options.length; optionIdx++) {
            const option = options[optionIdx];
            if (isHeaderOption && isHeaderOption(option)) {
                optionMatchPositions.push(-1);
                filteredOptListIdxMap.set(matchCount++, optionIdx);
                continue;
            }
            if (option.label == null) {
                continue;
            }
            const labelVal = typeof option.label === "string" ? option.label.toLowerCase() : "";
            const idx = labelVal.indexOf(filterVal);
            optionMatchPositions.push(idx);
            if (labelVal === filterVal && matchingIdxList.length < 2) {
                matchingIdxList.push(optionIdx);
            }
            if (idx !== -1) {
                filteredOptListIdxMap.set(matchCount++, optionIdx);
            }
        }
        const exactMatchIdx = matchingIdxList.length === 1 ? matchingIdxList[0] : null;
        return [optionMatchPositions, filteredOptListIdxMap, exactMatchIdx];
    }

    constructor(props: ISelectInputProps<T>) {
        super(props);
        const { initialFilterStr, value, valueKey, options } = props;
        const selectedOptionIdx = this.#findSelectedOptionIdx(
            value,
            valueKey,
            options as ISelectOption<T>[]
        );

        this.state = {
            containerWidth: 15,
            containterRect: null,
            selectedOptionIdx,
            expanded: false,
            filteredOptListIdxMap: null,
            highlightedIdx: null,
            openUpward: false,
            optionListHeight: this.#getOptionListHeight(null, props),
            optionMatchPositions: null,
            textInputValue:
                selectedOptionIdx === null
                    ? initialFilterStr || ""
                    : options
                    ? (options[selectedOptionIdx].label as string)
                    : "",
        };
    }

    UNSAFE_componentWillMount(): void {
        this.#removeDebounceListener(this._textInputNode);
        this._debouncedHandleInputResize = debounce(this.#handleInputResize, 125);
        this._resizeObserver = new ResizeObserver(this._debouncedHandleInputResize);
    }

    componentWillUnmount(): void {
        this.#removeDebounceListener(this._textInputNode);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISelectInputProps<T>): void {
        if (
            this.props.value === nextProps.value &&
            this.props.valueKey === nextProps.valueKey &&
            this.props.options === nextProps.options &&
            this.props.filterable === nextProps.filterable &&
            this.props.initialFilterStr === nextProps.initialFilterStr &&
            this.props.optionIsHeaderKey === nextProps.optionIsHeaderKey &&
            this.props.optionIsHiddenKey === nextProps.optionIsHiddenKey &&
            this.props.expanded === nextProps.expanded
        ) {
            return;
        }

        const { initialFilterStr, options, optionIsHeaderKey, value, valueKey } = nextProps;
        const selectedOptionIdx = this.#findSelectedOptionIdx(value, valueKey, options);

        const textInputValueChanged =
            this.props.value !== value ||
            (this.props.options !== options && selectedOptionIdx != null) ||
            this.props.initialFilterStr !== initialFilterStr;
        const textInputValue = !textInputValueChanged
            ? this.state.textInputValue
            : selectedOptionIdx === null
            ? initialFilterStr
            : (options[selectedOptionIdx].label as string);

        const isHeaderOption = (option: ISelectOption<T>) =>
            optionIsHeaderKey && option[optionIsHeaderKey];
        const noFilter = !this.props.filterable || selectedOptionIdx != null;
        const [optionMatchPositions, filteredOptListIdxMap, exactMatchIdx] = noFilter
            ? [null, null, null]
            : this.props.filterOptions
            ? this.props.filterOptions(textInputValue, options, isHeaderOption)
            : this.#filterOptions(textInputValue, options, isHeaderOption);

        const optionListHeight = this.#getOptionListHeight(
            filteredOptListIdxMap as Map<number, number>,
            nextProps
        );

        this.setState({
            filteredOptListIdxMap: filteredOptListIdxMap as Map<number, number>,
            optionListHeight,
            optionMatchPositions: optionMatchPositions as number[],
            selectedOptionIdx,
            textInputValue,
            highlightedIdx: exactMatchIdx as number,
        });
    }

    handleClickOutside = (): void => {
        if (!this.state.expanded) {
            return;
        }
        this.#onBlurWithoutSelection();
    };

    #handleInputResize = (): void => {
        const { optionListHeight } = this.state;
        const containterRect: DOMRect = this._textInputNode
            ? this._textInputNode.getBoundingClientRect()
            : null;
        const containerWidth: number = this._textInputNode ? this._textInputNode.offsetWidth : 15;
        const openUpward: boolean = window.innerHeight < containterRect.bottom + optionListHeight;
        this.setState({ containterRect, containerWidth, openUpward });
    };

    #findSelectedOptionIdx(value: T, valueKey: string, options: ISelectOption<T>[]): number {
        if (value == null || options == null) {
            return null;
        }

        for (let optionIdx = 0; optionIdx < options.length; optionIdx++) {
            if (typeof value === "object" && isEqual(options[optionIdx][valueKey], value)) {
                return optionIdx;
            }
            if (options[optionIdx][valueKey] === value) {
                return optionIdx;
            }
        }

        return null;
    }

    #getRowRenderer(): (rowRenderProps) => JSX.Element {
        const { noOptionsRenderer, optionIsHeaderKey, optionRenderer, options } = this.props;

        const {
            optionMatchPositions,
            selectedOptionIdx,
            filteredOptListIdxMap,
            highlightedIdx,
            textInputValue,
        } = this.state;

        const matchLen = textInputValue ? textInputValue.length : 0;

        /* eslint-disable no-unused-vars */
        return ({
            key, // Unique key within array of rows
            index, // Index of row within collection
            isScrolling, // The List is currently being scrolled
            style, // Style object to be applied to row (to position it)
        }) => {
            /* eslint-enable no-unused-vars */

            const unfilteredIndex =
                filteredOptListIdxMap != null ? filteredOptListIdxMap.get(index) : index;

            const option =
                options.length > 0 && unfilteredIndex != null ? options[unfilteredIndex] : null;

            if (option === null) {
                const optionProps = {
                    className: "select-form-input-option default",
                    key,
                    onMouseDown: (evt) => evt.preventDefault(),
                };
                return (
                    <div {...optionProps} style={style}>
                        {noOptionsRenderer({
                            typedVal: this.state.textInputValue,
                        })}
                    </div>
                );
            }

            const isHeader = Boolean(
                optionIsHeaderKey && options[unfilteredIndex][optionIsHeaderKey]
            );

            const isSelected = unfilteredIndex === selectedOptionIdx;
            const matchPos =
                optionMatchPositions == null ? -1 : optionMatchPositions[unfilteredIndex];
            const isHighlighted = !isScrolling && unfilteredIndex === highlightedIdx;

            const optionProps = {
                key: key,
                onMouseDown: (evt) => evt.preventDefault(),
                onClick: (evt) => this.#onOptionClicked(evt, unfilteredIndex),
                className: "select-input-option",
            };

            return (
                <div {...optionProps} style={style}>
                    {optionRenderer({
                        option,
                        isHeader,
                        isSelected,
                        matchPos,
                        isHighlighted,
                        matchLen,
                    })}
                </div>
            );
        };
    }

    #getOptionsPosition(): { top?: number; left?: number } {
        const { containterRect, openUpward, optionListHeight } = this.state;
        const { horizontalMenuAlign } = this.props;
        if (containterRect == null) {
            return {};
        }

        if (openUpward) {
            return {
                top: containterRect.top - optionListHeight,
            };
        }
        if (horizontalMenuAlign) {
            return {
                left: containterRect.left,
                top: containterRect.bottom,
            };
        }
        return {
            top: containterRect.bottom,
        };
    }

    #getOptionListHeight(
        filteredOptListIdxMap: Map<number, number>,
        props: ISelectInputProps<T>
    ): number {
        const {
            headerOptionHeight,
            options,
            optionHeight,
            optionIsHeaderKey,
            optionIsHiddenKey,
            maxHeight,
        } = props;

        const paddingHeight = 4;

        const optionCount =
            filteredOptListIdxMap != null ? filteredOptListIdxMap.size : options.length;

        if (optionCount === 0) {
            return optionHeight;
        }
        if (
            optionIsHiddenKey == null &&
            (optionIsHeaderKey == null || optionHeight === headerOptionHeight)
        ) {
            return Math.min(paddingHeight + optionCount * optionHeight, maxHeight);
        }

        // total up the height of the options
        const optionList =
            filteredOptListIdxMap === null
                ? options
                : [...filteredOptListIdxMap.values()].map((idx) => options[idx]);

        let height = paddingHeight;
        for (const option of optionList) {
            if (
                optionIsHiddenKey == null ||
                (option.value != null &&
                    option.value[optionIsHiddenKey] == null &&
                    option[optionIsHiddenKey] == null) ||
                (option.value != null &&
                    option.value[optionIsHiddenKey] != null &&
                    option.value[optionIsHiddenKey]) ||
                (option[optionIsHiddenKey] != null && option[optionIsHiddenKey])
            ) {
                height += option[optionIsHeaderKey] ? headerOptionHeight : optionHeight;
            }
            if (height > maxHeight) {
                return maxHeight;
            }
        }
        return height;
    }

    public _onBlurWithNewSelection(optionIdx: number): void {
        const { onChange, onInputChange, options } = this.props;
        const { textInputValue } = this.state;

        const optionLabel = options[optionIdx].label;

        this.#resetFilterState(() => {
            this.setState(
                {
                    expanded: false,
                    selectedOptionIdx: optionIdx,
                    textInputValue: optionLabel as string,
                },
                (): void => {
                    if (textInputValue !== optionLabel) {
                        onInputChange(optionLabel as string);
                    }
                    onChange(options[optionIdx][this.props.valueKey]);
                    this.textInput.blur();
                }
            );
        });
    }

    #onBlurWithoutSelection(isArrow = false): void {
        const { clearFilterInputOnBlur, options, onInputChange } = this.props;
        const { selectedOptionIdx, textInputValue } = this.state;

        const resetFilterAndUpdateTextInput = (newTextInputValue, isArrow = false) => {
            this.#resetFilterState(() => {
                this.setState(
                    {
                        expanded: isArrow ? this.state.expanded : false,
                        textInputValue: newTextInputValue,
                    },
                    () => {
                        if (textInputValue !== newTextInputValue) {
                            onInputChange(newTextInputValue);
                        }
                        if (!isArrow) {
                            this.textInput.blur();
                        }
                    }
                );
            });
        };

        if (selectedOptionIdx != null) {
            resetFilterAndUpdateTextInput(options[selectedOptionIdx].label, isArrow);
            return;
        }

        if (clearFilterInputOnBlur) {
            resetFilterAndUpdateTextInput("", isArrow);
            return;
        }

        if (!isArrow) {
            this.setState({ expanded: false }, () => {
                this.textInput.blur();
            });
        }
    }

    #onClear(): void {
        const { onChange, onInputChange } = this.props;
        const { textInputValue } = this.state;

        this.#resetFilterState(() => {
            this.setState(
                {
                    selectedOptionIdx: null,
                    textInputValue: "",
                },
                () => {
                    if (textInputValue !== "") {
                        onInputChange("");
                    }
                    onChange(undefined);
                }
            );
        });
    }

    #onExpandArrowMouseDown(event: MouseEvent, isArrow = false): void {
        if (this.props.disabled) {
            return;
        }

        const { expanded } = this.state;
        if (!expanded) {
            event.preventDefault();
        }

        this.setState(
            {
                expanded: !expanded,
            },
            () => {
                if (!expanded) {
                    this.textInput.focus();
                }
                if (expanded || isArrow) {
                    this.#onBlurWithoutSelection(isArrow);
                }
            }
        );
    }

    #onInputBlur(): void {
        /**
         * When the user clicks the scrollbar in the options list, it will un-focus
         *  the `<TextInput />`.  We want to re-focus the input in this case, but
         *  we have to be careful to not get into a blur() -> focus() loop with
         *  another `<SelectInput />`.
         */
        const nothingIsActive: boolean = document.activeElement === document.body;
        const selfIsActive: boolean =
            this.containerRef != null && this.containerRef.contains(document.activeElement);
        if (this.state.expanded && (nothingIsActive || selfIsActive)) {
            this.textInput.focus();
        } else {
            this.props.onBlur();
            if (AppHelpers.isIE()) {
                // IE for some reason doesn't repaint everything that was covered
                //  by the options when they're removed from the DOM .. this forces
                //  a repaint.
                setImmediate(() => {
                    document.body.style.paddingLeft = "1px";
                    setTimeout(() => {
                        document.body.style.paddingLeft = "";
                    }, 100);
                });
            }
        }
    }

    #onInputChange(newValue: string): void {
        const { clearOnEmptyFilterStr, filterable, onInputChange, optionIsHeaderKey, options } =
            this.props;

        if (!filterable || newValue === this.state.textInputValue) {
            return;
        }

        if (newValue === "" && clearOnEmptyFilterStr) {
            this.#onClear();
            return;
        }

        const isHeaderOption = (option: ISelectOption<T>) =>
            optionIsHeaderKey && option[optionIsHeaderKey];

        const [optionMatchPositions, filteredOptListIdxMap, exactMatchIdx] =
            newValue === ""
                ? [null, null, null]
                : this.props.filterOptions
                ? this.props.filterOptions(newValue, options, isHeaderOption)
                : this.#filterOptions(newValue, options, isHeaderOption);

        const optionListHeight = this.#getOptionListHeight(
            filteredOptListIdxMap as Map<number, number>,
            this.props
        );

        this.setState(
            {
                filteredOptListIdxMap: filteredOptListIdxMap as Map<number, number>,
                optionListHeight,
                optionMatchPositions: optionMatchPositions as number[],
                highlightedIdx: exactMatchIdx as number,
                textInputValue: newValue,
            },
            () => {
                onInputChange(newValue);
            }
        );
    }

    #onInputFocus(): void {
        let textInputValue = this.state.textInputValue;
        let expanded = this.state.expanded;

        if (this.props.clearOnFocus) {
            textInputValue = "";
        }
        if (this.props.openOnFocus) {
            expanded = true;
        }

        this.setState(
            {
                expanded,
                textInputValue,
            },
            () => {
                if (this.props.clearOnFocus) {
                    this.props.onInputChange(textInputValue);
                }
                this.props.onFocus();
            }
        );
    }

    #onOptionClicked(event: MouseEvent, optionIdx: number): void {
        if (event) {
            event.preventDefault();
        }

        const { optionIsHeaderKey, options } = this.props;
        if (optionIsHeaderKey && options[optionIdx][optionIsHeaderKey]) {
            // ignore option clicks if the options is a "header"
            return;
        }

        this._onBlurWithNewSelection(optionIdx);
    }

    #onOuterDivKeyDown(event: KeyboardEvent): void {
        const { disabled, filterable, optionIsHeaderKey, options } = this.props;

        const { expanded, optionMatchPositions, selectedOptionIdx, highlightedIdx } = this.state;

        if (disabled) {
            return;
        }

        if (!expanded) {
            if (event.key === "Escape") {
                this.textInput.blur();
                return;
            }
            if (this.textInput.state.hasFocus) {
                event.persist();
                this.setState(
                    {
                        expanded: true,
                    },
                    () => {
                        this.#onOuterDivKeyDown(event);
                    }
                );
                return;
            }
        }

        if (event.key === "Tab" || event.key === "Enter" || event.key === "Escape") {
            if (event.key !== "Escape" && highlightedIdx != null) {
                this._onBlurWithNewSelection(highlightedIdx);
                return;
            }

            this.#onBlurWithoutSelection();

            return;
        }

        const optionIdxIsFiltered = (optionIdx) =>
            filterable && optionMatchPositions && optionMatchPositions[optionIdx] === -1;
        const optionIdxIsHeader = (optionIdx) =>
            optionIsHeaderKey && options[optionIdx][optionIsHeaderKey];

        if (event.key === "ArrowDown") {
            event.preventDefault();
            let nextSelectedIdx =
                highlightedIdx != null
                    ? highlightedIdx
                    : selectedOptionIdx != null
                    ? selectedOptionIdx
                    : -1;
            do {
                nextSelectedIdx++;
            } while (
                nextSelectedIdx < options.length &&
                (optionIdxIsFiltered(nextSelectedIdx) || optionIdxIsHeader(nextSelectedIdx))
            );

            if (optionIdxIsFiltered(nextSelectedIdx) || optionIdxIsHeader(nextSelectedIdx)) {
                return;
            }

            this.setState({
                highlightedIdx: Math.min(nextSelectedIdx, options.length - 1),
            });

            return;
        }

        if (event.key === "ArrowUp") {
            event.preventDefault();
            let nextSelectedIdx =
                highlightedIdx != null
                    ? highlightedIdx
                    : selectedOptionIdx != null
                    ? selectedOptionIdx
                    : options.length;
            do {
                nextSelectedIdx--;
            } while (
                nextSelectedIdx >= 0 &&
                (optionIdxIsFiltered(nextSelectedIdx) || optionIdxIsHeader(nextSelectedIdx))
            );

            if (optionIdxIsFiltered(nextSelectedIdx) || optionIdxIsHeader(nextSelectedIdx)) {
                return;
            }

            this.setState({ highlightedIdx: Math.max(nextSelectedIdx, 0) });
        }
    }

    #onOuterDivMouseDown(event: MouseEvent): void {
        if (!event.isDefaultPrevented() && !this.state.expanded) {
            this.#onExpandArrowMouseDown(event);
        }
    }

    #removeDebounceListener(textInputNode: Node): void {
        if (this._resizeObserver != null) {
            this._resizeObserver.disconnect();
        }
        if (this._debouncedHandleInputResize != null) {
            this._debouncedHandleInputResize.cancel();
        }
        if (textInputNode != null) {
            getScrollParent(textInputNode as Element).removeEventListener(
                "scroll",
                this._debouncedHandleInputResize
            );
        }
    }

    #renderOptionsElement(): JSX.Element {
        const {
            expanded,
            highlightedIdx,
            selectedOptionIdx,
            filteredOptListIdxMap,
            optionListHeight,
        } = this.state;

        if (!expanded) {
            return null;
        }

        const {
            headerOptionHeight,
            options,
            minOptionsWidth,
            noOptionsRenderer,
            optionHeight,
            optionIsHeaderKey,
            optionIsHiddenKey,
        } = this.props;

        if (
            (options.length === 0 ||
                (filteredOptListIdxMap != null && filteredOptListIdxMap.size === 0)) &&
            noOptionsRenderer === null
        ) {
            return null;
        }

        const rowRenderer = this.#getRowRenderer();

        let optionCount =
            filteredOptListIdxMap != null ? filteredOptListIdxMap.size : options.length;

        if (optionCount === 0) {
            optionCount = 1;
        }

        const getRowHeight =
            optionIsHiddenKey == null &&
            (optionIsHeaderKey == null || optionHeight === headerOptionHeight)
                ? optionHeight
                : ({ index }) => {
                      const option = filteredOptListIdxMap
                          ? options[filteredOptListIdxMap.get(index)]
                          : options[index];
                      return option
                          ? optionIsHiddenKey != null &&
                            ((option.value != null &&
                                option.value[optionIsHiddenKey] != null &&
                                !option.value[optionIsHiddenKey]) ||
                                (option[optionIsHiddenKey] != null && !option[optionIsHiddenKey]))
                              ? 0
                              : option[optionIsHeaderKey]
                              ? headerOptionHeight
                              : optionHeight
                          : 0;
                  };

        const optionListWidth =
            minOptionsWidth > this.state.containerWidth
                ? minOptionsWidth
                : this.state.containerWidth;

        const listProps: ListProps = {
            className: "form-input select-form-input-options",
            rowCount: optionCount,
            rowHeight: getRowHeight,
            height: optionListHeight,
            rowRenderer,
            width: optionListWidth,
        };

        if (highlightedIdx != null || selectedOptionIdx != null) {
            if (highlightedIdx != null) {
                let scrollIdx = highlightedIdx;
                if (filteredOptListIdxMap != null) {
                    for (const [filteredIdx, optionIdx] of filteredOptListIdxMap) {
                        if (optionIdx === highlightedIdx) {
                            scrollIdx = filteredIdx;
                            break;
                        }
                    }
                }
                listProps.scrollToIndex = scrollIdx;
                listProps.scrollToAlignment = "auto";
            } else {
                console.assert(selectedOptionIdx != null);
                if (filteredOptListIdxMap === null) {
                    listProps.scrollToIndex = selectedOptionIdx;
                    listProps.scrollToAlignment = "start";
                }
            }
        }

        listProps.style = {
            position: "fixed",
            ...this.#getOptionsPosition(),
        };

        return <List {...listProps} ref={(ref) => (this.listRef = ref)} />;
    }

    #resetFilterState(callback: () => void = () => null): void {
        this.setState(
            {
                filteredOptListIdxMap: null,
                highlightedIdx: null,
                optionMatchPositions: null,
                optionListHeight: this.#getOptionListHeight(null, this.props),
            },
            callback
        );
    }

    #setTextInputRef(textInput: TextInput): void {
        this.textInput = textInput;
        const textInputNode: Element =
            textInput == null
                ? null
                : // eslint-disable-next-line react/no-find-dom-node
                  (ReactDOM.findDOMNode(textInput) as Element);

        if (textInputNode != null) {
            this.#removeDebounceListener(textInputNode);
            this._resizeObserver.observe(textInputNode);
            getScrollParent(textInputNode).addEventListener(
                "scroll",
                this._debouncedHandleInputResize,
                { passive: true }
            );
            this._textInputNode = textInputNode as HTMLInputElement;
            return;
        }

        this.#removeDebounceListener(this._textInputNode);
    }

    shouldComponentUpdate(nextProps: ISelectInputProps<T>, nextState: ISelectInputState): boolean {
        if (
            this.props.allowEmptyOptions !== nextProps.allowEmptyOptions ||
            this.props.containerClassNames !== nextProps.containerClassNames ||
            this.props.options !== nextProps.options ||
            this.props.valueKey !== nextProps.valueKey ||
            this.state.expanded !== nextState.expanded ||
            this.state.openUpward !== nextState.openUpward ||
            this.state.textInputValue !== nextState.textInputValue ||
            this.props.disabled !== nextProps.disabled
        ) {
            return true;
        }
        return false;
    }

    render(): JSX.Element {
        const { options, valueKey, allowEmptyOptions } = this.props;
        const hasOnlyInActive: boolean = hasOnlyInActiveOptions(
            options,
            valueKey,
            allowEmptyOptions
        );
        const optionsElement: JSX.Element = !hasOnlyInActive ? this.#renderOptionsElement() : null;

        const inputContainerRightElements = this.props.inputContainerRightElements || [];
        const arrow = this.props.arrowRenderer({
            onMouseDown: (evt) => this.#onExpandArrowMouseDown(evt, true),
            isOpen: this.state.expanded,
        });
        if (arrow) {
            inputContainerRightElements.push(
                <span key="arrow" className="expand-contract-arrow">
                    {arrow}
                </span>
            );
        }

        const textInputContainerClassNames = [
            "select-form-input",
            {
                "select-form-input-expanded": this.state.expanded && optionsElement != null,
            },
        ];

        const containerClassNames = classnames(
            ...this.props.containerClassNames,
            "select-form-input-container",
            {
                "open-upward": this.state.openUpward,
            }
        );

        return (
            <div
                className={containerClassNames}
                onMouseDown={(evt) => this.#onOuterDivMouseDown(evt)}
                onKeyDown={(evt) => this.#onOuterDivKeyDown(evt)}
                ref={(containerRef) => (this.containerRef = containerRef)}
            >
                <TextInput
                    autoFocus={this.props.autoFocus}
                    clearable={this.props.clearable}
                    disabled={this.props.disabled || hasOnlyInActive}
                    required={this.props.required}
                    tabIndex={this.props.tabIndex}
                    visible={this.props.visible}
                    placeholderText={this.props.placeholderText}
                    containerClassNames={textInputContainerClassNames}
                    value={this.state.textInputValue}
                    inputContainerRightElements={inputContainerRightElements}
                    inputContainerLeftElements={this.props.inputContainerLeftElements}
                    inputCSS={this.props.inputCSS}
                    onBlur={() => this.#onInputBlur()}
                    onChange={(newValue) => this.#onInputChange(newValue)}
                    onClear={() => this.#onClear()}
                    onFocus={() => this.#onInputFocus()}
                    readOnly={!this.props.filterable}
                    ref={(textInput) => this.#setTextInputRef(textInput)}
                    showTopLabel={this.props.showTopLabel}
                    title={this.props.valueTitle || this.state.textInputValue}
                    maxLength={this.props.maxLength}
                    preFixinputContainerRightElements={this.props.preFixinputContainerRightElements}
                    hasError={this.props.hasError}
                    errorText={this.props.errorText}
                />

                {optionsElement}
            </div>
        );
    }
}

export class SelectInput<T> extends Component<ISelectInputProps<T>, ISelectInputState> {
    WrappedSelectInput = onClickOutside(SelectInput_);
    render(): JSX.Element {
        return (
            <this.WrappedSelectInput {...this.props}>{this.props.children}</this.WrappedSelectInput>
        );
    }
}
