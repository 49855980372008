import { getModuleState as getEventInfoModuleState } from "../../selectors";

export const SAMPLE_TISSUE_STATE_KEY = "SAMPLE_TISSUE_STATE";

export const getModuleState = (state: unknown): any =>
    getEventInfoModuleState(state)[SAMPLE_TISSUE_STATE_KEY];

export const getCropGrowthStageList = (state: unknown): any =>
    getEventInfoModuleState(state)[SAMPLE_TISSUE_STATE_KEY].cropGrowthStageList;

export const getCropGrowthStageGuid = (state: unknown): any =>
    getEventInfoModuleState(state)[SAMPLE_TISSUE_STATE_KEY].cropGrowthStageGuid;

export const getPlantPartGuid = (state: unknown): any =>
    getEventInfoModuleState(state)[SAMPLE_TISSUE_STATE_KEY].plantPartGuid;
