import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import moment from "moment";

import { AppHelpers, apiUrl } from "@ai360/core";
import SearchAgvanceField from "~/action-panel/components/common/search-agvance-field";
import { actions } from "~/action-panel/components/field-module/";
import { IrrigatedListOptions } from "../../../../sagas";
import { fetchedDropdownData } from "~/action-panel/components/field-module/actions";
import {
    getFieldModuleDropdowns,
    getAgvanceFieldList,
    getAgvanceFieldClassificationList,
    getAgvanceOrgLevelList,
    getErrorCodes,
    getAgvanceFieldData,
    getFetchingFieldInfo,
} from "~/action-panel/components/field-module/selectors";
import { adminData, GUID, NAME, PICKLIST_GUID, VALUE } from "~/admin/data";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";
import {
    Bucket,
    BucketHeader,
    Checkbox,
    DateInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    NumericInput,
    SelectInput,
    SelectInputMulti,
    TextArea,
    TextInput,
} from "~/core";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { TrashcanIcon } from "~/core/icons";
import { selectors as picklistSelectors } from "~/core/picklist";
import {
    PICKLIST_CROP_PURPOSE,
    PICKLIST_IRRIGATION_TYPE,
    PICKLIST_INSURANCE_POLICY_TYPE,
    PICKLIST_LAND_OWNERSHIP_TYPE,
    PICKLIST_PRODUCT_PARENT_TYPE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { selectors as unitSelectors } from "~/core/units";
import { UNIT_YIELD, getUnitCode } from "~/core/units/unit-names";
import { withApiResult } from "~/hocs";
import { messages as globalMessages } from "~/i18n-messages";
import { getTheUserPersonalityId, getUser } from "~/login/selectors";
import { optionRendererWithCustomTitle } from "~/action-panel/components/common/validation-utils";
import { fetchOccupiedFarmNames } from "~/utils/api/field";

import messages from "./i18n-messages";

import "./field-information-detail.css";
import { IAgvClassification } from "@ai360/core/dist/4x/es/api/field/field";
import { IFieldModel } from "~/action-panel/components/field-module/models";
import { logFirebaseEvent } from "~/utils/firebase";
import FieldBoundaryTransfer from "../field-boundary-transfer/field-boundary-transfer";

export const pickLists = {
    [PICKLIST_CROP_PURPOSE]: getPickListCode(PICKLIST_CROP_PURPOSE),
    [PICKLIST_IRRIGATION_TYPE]: getPickListCode(PICKLIST_IRRIGATION_TYPE),
    [PICKLIST_INSURANCE_POLICY_TYPE]: getPickListCode(PICKLIST_INSURANCE_POLICY_TYPE),
    [PICKLIST_LAND_OWNERSHIP_TYPE]: getPickListCode(PICKLIST_LAND_OWNERSHIP_TYPE),
    [PICKLIST_PRODUCT_PARENT_TYPE]: getPickListCode(PICKLIST_PRODUCT_PARENT_TYPE),
};
export const units = {
    [UNIT_YIELD]: getUnitCode(UNIT_YIELD),
};

const DROPDOWN_CROP = "DROPDOWN_CROP";
const DROPDOWN_BRANDORGANIZATION = "DROPDOWN_BRANDORGANIZATION";
const DROPDOWN_VARIETYHYBRID = "DROPDOWN_VARIETYHYBRID";

export const dropdowns = {
    [DROPDOWN_CROP]: apiUrl("AgBytes/GetCropDropdownList"),
    [DROPDOWN_BRANDORGANIZATION]: {
        url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
        model: "",
    },
    [DROPDOWN_VARIETYHYBRID]: {
        url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
        model: { cropId: "", brandOrganization: "" },
    },
};

interface IProps {
    activeField: IFieldModel;
    agvanceFieldData: any;
    agvanceFieldList: any[];
    agvanceOrgList: any[];
    agvanceFieldClassificationList: IAgvClassification[];
    apiError: any;
    clearAgvanceFieldList: () => void;
    clearAgvanceFieldClassificationList: () => void;
    customerGuid: string;
    editFieldGuid: string;
    errorCodes: any[];
    fetchAgvanceFieldList: (customerGuid: string) => void;
    fetchAgvanceFieldClassificationList: (orgLevelGuid: string) => void;
    fetchAgvanceOrgLevelList: (customerGuid: string) => void;
    fetchingFieldInfo: boolean;
    hasActiveInactive: boolean;
    intl: any;
    personalityId: number;
    refreshDropdowns: (dropdowns: any) => void;
    updateFieldModel: (model: any) => void;
    userInfo: any;
}

interface IState {
    customerFarms: Set<string>;
    agvanceFieldAcres: any;
    insurance: any;
    lease: any;
    errorCodes: any[];
    agvanceCustomer: string;
    showCertOrganicChangeDialog: boolean;
    newCertOrganicValue: boolean;
    shouldGetAgvanceCustomer: boolean;
    [key: string]: any;
}

class FieldInformationDetail extends React.Component<IProps, IState> {
    private cropListOptions: any[];
    private cropLocalOptions: any[];
    private brandListOptions: any[];
    private varietyListOptions: any[];
    private cropPurposeListOptions: any[];
    private cropPurposeLocalOptions: any[];
    private irrigationTypeListOptions: any[];
    private insurancePolicyTypeListOptions: any[];
    private leaseTypeListOptions: any[];
    private parentProductTypeListOptions: any[];
    private yieldUnitListOptions: any[];
    private farmListOptions: any[];
    private irrigatedListOptions: any[];

    /// ----------------------------------------- ///

    constructor(props: IProps) {
        super(props);

        this.state = {
            agvanceFieldAcres: null,
            insurance: {
                cropGuid: "",
                cropName: "",
                cropPurposeGuid: "",
                cropPurposeName: "",
                yieldUnitGuid: "",
                yieldUnitName: "",
                countyTYield: null,
                averageYield: null,
                calculatedYield: null,
                fieldGuid: "",
                fieldCropYieldGuid: "",
            },
            lease: {
                landlordName: "",
                cropSharePercent: null,
                parentProductTypeGuid: "",
                parentProductTypeName: "",
                productTypeSharePercent: null,
                fieldGuid: "",
                fieldLeaseGuid: "",
            },
            multiSelectIndex: 0,
            errorCodes: [],
            agvanceCustomer: "",
            showCertOrganicChangeDialog: false,
            newCertOrganicValue: undefined,
            customerFarms: new Set(),
            shouldGetAgvanceCustomer: false,
        };

        this.setupLists(props, null);
    }

    async componentDidMount() {
        if (this.props.customerGuid) {
            this.agvanceInit(this.props.customerGuid);

            const farmNames = await fetchOccupiedFarmNames(
                this.props.customerGuid,
                true,
                this.props.userInfo.userGuid
            );
            this.setState({
                customerFarms: new Set(farmNames),
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        this.setupLists(nextProps, this.props);
        if (nextProps.agvanceOrgList !== this.props.agvanceOrgList) {
            if (nextProps.agvanceOrgList.length === 1) {
                this.updateFieldForAgvanceCustomers(
                    nextProps.agvanceOrgList[0],
                    nextProps.activeField
                );
            }

            this.setState({
                shouldGetAgvanceCustomer: true,
            });
        }
        if (
            this.state.shouldGetAgvanceCustomer &&
            !nextProps.fetchingFieldInfo &&
            !this.state.agvanceCustomer &&
            this.showAdditionalAgvanceCustomer(nextProps)
        ) {
            this.setState({
                agvanceCustomer: this.getAgvanceCustomer(
                    nextProps.agvanceOrgList,
                    nextProps.activeField
                ),
                shouldGetAgvanceCustomer: false,
            });
        }
        if (nextProps.agvanceFieldData != null) {
            this.setState({
                agvanceFieldAcres: nextProps.agvanceFieldData.acres,
            });
        }
        if (nextProps.activeField?.classifications !== this.props.activeField?.classifications) {
            this.setState({
                multiSelectIndex: this.state.multiSelectIndex + 1,
            });
        }
    }

    componentWillUnmount() {
        if (this.props.agvanceFieldList.length) {
            this.props.clearAgvanceFieldList();
        }
        this.props.clearAgvanceFieldClassificationList();
    }

    private agvanceInit(customerGuid: string) {
        const { fetchAgvanceFieldList, fetchAgvanceOrgLevelList } = this.props;
        fetchAgvanceFieldList(customerGuid);
        fetchAgvanceOrgLevelList(customerGuid);
    }

    private updateFieldForAgvanceCustomers(
        selectedItem: any,
        activeField: any,
        modifiedFieldModel: any = {}
    ) {
        const { updateFieldModel, fetchAgvanceFieldClassificationList } = this.props;
        const newFieldModel = {
            ...activeField,
            agvanceFieldOrgLevelGuid: selectedItem.orgLevelGuid,
            agvanceCustomerGuid: selectedItem.agvanceCustomerGuid,
            ...modifiedFieldModel,
        };
        if (AgvanceUtils.isAgvanceConnected(this.props.personalityId)) {
            fetchAgvanceFieldClassificationList(selectedItem.orgLevelGuid);
        }

        updateFieldModel(newFieldModel);
    }

    private getSelectableOptions(options: any[], guid: string, label: string, selected: any) {
        if (options) {
            return options.reduce((result, option) => {
                result.push({
                    value: option[guid],
                    label: option[label],
                    selected: option[guid] === selected,
                    activeYn: option[adminData.PROPS_ACTIVE_YN],
                });
                return result;
            }, []);
        }
        return [];
    }

    private selectValue(options: any[], value: any) {
        return options.map((option) => {
            return {
                value: option.value,
                label: option.label,
                selected: option.value === value,
                activeYn: option.activeYn,
            };
        });
    }

    private clearSelection(options: any[]) {
        return options.map((option) => {
            return {
                value: option.value,
                label: option.label,
                selected: false,
                activeYn: option.activeYn,
            };
        });
    }

    private getLabelByValue(options: any[], value: any) {
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            if (option.value === value) {
                return option.label;
            }
        }
    }

    private refreshCropDropdownLists(cropGuid: string, brandOrganizationGuid: string) {
        const refreshDropdowns = {
            [DROPDOWN_BRANDORGANIZATION]: {
                url: apiUrl("AgBytes/GetBrandOrganizationCropList"),
                model: cropGuid,
            },
            [DROPDOWN_VARIETYHYBRID]: {
                url: apiUrl("AgBytes/GetVarietyHybridFilterList"),
                model: {
                    cropId: cropGuid,
                    brandOrganization: brandOrganizationGuid,
                },
            },
        };
        this.props.refreshDropdowns(refreshDropdowns);
    }

    /// Set up lists ///
    private setupLists(props: IProps, oldProps: IProps) {
        const isInit = !oldProps;

        if (isInit || props[DROPDOWN_CROP] !== oldProps[DROPDOWN_CROP]) {
            this.cropListOptions = this.getSelectableOptions(
                props[DROPDOWN_CROP],
                "cropGuid",
                "cropName",
                props.activeField.cropGuid
            );
            this.cropLocalOptions = this.getSelectableOptions(
                props[DROPDOWN_CROP],
                "cropGuid",
                "cropName",
                this.state.insurance.cropGuid
            );
            props.activeField.fieldCropYieldList?.forEach((cropYield) => {
                cropYield.cropName = this.getLabelByValue(
                    this.cropLocalOptions,
                    cropYield.cropGuid
                );
            });
        }
        if (isInit || props[DROPDOWN_BRANDORGANIZATION] !== oldProps[DROPDOWN_BRANDORGANIZATION]) {
            this.brandListOptions = this.getSelectableOptions(
                props[DROPDOWN_BRANDORGANIZATION],
                GUID,
                NAME,
                props.activeField.brandOrganizationGuid
            );
        }
        if (isInit || props[DROPDOWN_VARIETYHYBRID] !== oldProps[DROPDOWN_VARIETYHYBRID]) {
            this.varietyListOptions = this.getSelectableOptions(
                props[DROPDOWN_VARIETYHYBRID],
                GUID,
                NAME,
                props.activeField.varietyHybridGuid
            );
        }
        if (isInit || props[PICKLIST_CROP_PURPOSE] !== oldProps[PICKLIST_CROP_PURPOSE]) {
            this.cropPurposeListOptions = this.getSelectableOptions(
                props[PICKLIST_CROP_PURPOSE],
                PICKLIST_GUID,
                VALUE,
                props.activeField.cropPurposeGuid
            );
            this.cropPurposeLocalOptions = this.getSelectableOptions(
                props[PICKLIST_CROP_PURPOSE],
                PICKLIST_GUID,
                VALUE,
                this.state.insurance.cropPurposeGuid
            );
            props.activeField.fieldCropYieldList?.forEach((cropYield) => {
                cropYield.cropPurposeName = this.getLabelByValue(
                    this.cropPurposeLocalOptions,
                    cropYield.cropPurposeGuid
                );
            });
        }
        if (isInit || props[PICKLIST_IRRIGATION_TYPE] !== oldProps[PICKLIST_IRRIGATION_TYPE]) {
            this.irrigationTypeListOptions = this.getSelectableOptions(
                props[PICKLIST_IRRIGATION_TYPE],
                PICKLIST_GUID,
                VALUE,
                props.activeField.irrigationTypeGuid
            );
        }
        if (
            isInit ||
            props[PICKLIST_INSURANCE_POLICY_TYPE] !== oldProps[PICKLIST_INSURANCE_POLICY_TYPE]
        ) {
            this.insurancePolicyTypeListOptions = this.getSelectableOptions(
                props[PICKLIST_INSURANCE_POLICY_TYPE],
                PICKLIST_GUID,
                VALUE,
                props.activeField.insurancePolicyTypeGuid
            );
        }
        if (
            isInit ||
            props[PICKLIST_LAND_OWNERSHIP_TYPE] !== oldProps[PICKLIST_LAND_OWNERSHIP_TYPE]
        ) {
            this.leaseTypeListOptions = this.getSelectableOptions(
                props[PICKLIST_LAND_OWNERSHIP_TYPE],
                PICKLIST_GUID,
                VALUE,
                props.activeField.landOwnershipTypeGuid
            );
        }
        if (
            isInit ||
            props[PICKLIST_PRODUCT_PARENT_TYPE] !== oldProps[PICKLIST_PRODUCT_PARENT_TYPE]
        ) {
            this.parentProductTypeListOptions = this.getSelectableOptions(
                props[PICKLIST_PRODUCT_PARENT_TYPE],
                PICKLIST_GUID,
                VALUE,
                this.state.lease.parentProductTypeGuid
            );
            props.activeField.fieldLeaseList?.forEach((lease) => {
                lease.parentProductTypeName = this.getLabelByValue(
                    this.parentProductTypeListOptions,
                    lease.parentProductTypeGuid
                );
            });
        }
        if (isInit || props[UNIT_YIELD] !== oldProps[UNIT_YIELD]) {
            this.yieldUnitListOptions = this.getSelectableOptions(
                props[UNIT_YIELD],
                GUID,
                NAME,
                this.state.insurance.yieldUnitGuid
            );
            props.activeField.fieldCropYieldList?.forEach((cropYield) => {
                cropYield.yieldUnitName = this.getLabelByValue(
                    this.yieldUnitListOptions,
                    cropYield.yieldUnitGuid
                );
            });
        }

        if (
            isInit ||
            props.activeField.fieldCropYieldList !== oldProps.activeField.fieldCropYieldList
        ) {
            props.activeField.fieldCropYieldList?.forEach((cropYield) => {
                cropYield.cropName = this.getLabelByValue(
                    this.cropLocalOptions,
                    cropYield.cropGuid
                );
                cropYield.cropPurposeName = this.getLabelByValue(
                    this.cropPurposeLocalOptions,
                    cropYield.cropPurposeGuid
                );
                cropYield.yieldUnitName = this.getLabelByValue(
                    this.yieldUnitListOptions,
                    cropYield.yieldUnitGuid
                );
            });
        }
        if (isInit || props.activeField.fieldLeaseList !== oldProps.activeField.fieldLeaseList) {
            props.activeField.fieldLeaseList?.forEach((lease) => {
                lease.parentProductTypeName = this.getLabelByValue(
                    this.parentProductTypeListOptions,
                    lease.parentProductTypeGuid
                );
            });
        }
        if (isInit || this.farmListOptions.length !== this.state.customerFarms.size) {
            this.setUpFarmListOptions();
        }

        if (isInit || props.activeField.irrigated !== oldProps.activeField.irrigated) {
            this.setUpIrrigatedListOptions();
        }
        if (isInit || props.activeField.cropGuid !== oldProps.activeField.cropGuid) {
            this.cropListOptions = this.selectValue(
                this.cropListOptions,
                props.activeField.cropGuid
            );
        }
        if (
            isInit ||
            props.activeField.brandOrganizationGuid !== oldProps.activeField.brandOrganizationGuid
        ) {
            this.brandListOptions = this.selectValue(
                this.brandListOptions,
                props.activeField.brandOrganizationGuid
            );
        }
        if (
            isInit ||
            props.activeField.varietyHybridGuid !== oldProps.activeField.varietyHybridGuid
        ) {
            this.varietyListOptions = this.selectValue(
                this.varietyListOptions,
                props.activeField.varietyHybridGuid
            );
        }
        if (isInit || props.activeField.cropPurposeGuid !== oldProps.activeField.cropPurposeGuid) {
            this.cropPurposeListOptions = this.selectValue(
                this.cropPurposeListOptions,
                props.activeField.cropPurposeGuid
            );
        }
        if (
            isInit ||
            props.activeField.irrigationTypeGuid !== oldProps.activeField.irrigationTypeGuid
        ) {
            this.irrigationTypeListOptions = this.selectValue(
                this.irrigationTypeListOptions,
                props.activeField.irrigationTypeGuid
            );
        }
        if (
            isInit ||
            props.activeField.insurancePolicyTypeGuid !==
                oldProps.activeField.insurancePolicyTypeGuid
        ) {
            this.insurancePolicyTypeListOptions = this.selectValue(
                this.insurancePolicyTypeListOptions,
                props.activeField.insurancePolicyTypeGuid
            );
        }
        if (
            isInit ||
            props.activeField.landOwnershipTypeGuid !== oldProps.activeField.landOwnershipTypeGuid
        ) {
            this.leaseTypeListOptions = this.selectValue(
                this.leaseTypeListOptions,
                props.activeField.landOwnershipTypeGuid
            );
        }
    }

    private setUpFarmListOptions() {
        this.farmListOptions = [...this.state.customerFarms].map((farmName) => ({
            value: farmName,
            label: farmName,
        }));
    }

    private setUpIrrigatedListOptions() {
        this.irrigatedListOptions = IrrigatedListOptions;
    }
    ////////////////////

    /// Event Handlers ///
    private updateFieldModel(propertyName: string, newValue: any) {
        // Takes a property name (possibly nested, e.g. fieldBoundary.watershedName) and updates the field model with
        // the new value.
        const newFieldModel = { ...this.props.activeField };
        let modelName = "";
        let propName = "";

        if (propertyName.indexOf(".") !== -1) {
            modelName = propertyName.split(".")[0];
            propName = propertyName.split(".")[1];
        }

        if (modelName !== "") {
            newFieldModel[modelName][propName] = newValue;
        } else {
            if (propertyName === "cropGuid") {
                newFieldModel.cropPurposeGuid = "";
                newFieldModel.brandOrganizationGuid = "";
                newFieldModel.varietyHybridGuid = "";
                this.cropPurposeListOptions = this.getSelectableOptions(
                    this.props[PICKLIST_CROP_PURPOSE],
                    PICKLIST_GUID,
                    VALUE,
                    null
                );
                this.brandListOptions = [];
                this.varietyListOptions = [];
                this.refreshCropDropdownLists(newValue, "");
            } else if (propertyName === "brandOrganizationGuid") {
                newFieldModel.varietyHybridGuid = "";
                this.varietyListOptions = [];
                this.refreshCropDropdownLists(newFieldModel.cropGuid, newValue);
            } else if (propertyName === "irrigated" && !newValue) {
                newFieldModel.irrigationTypeGuid = "";
            }
            newFieldModel[propertyName] = newValue;
        }
        this.props.updateFieldModel(newFieldModel);
    }

    private updateLocalModel(model: string, propertyName: string, newValue: any) {
        const objModel = { ...this.state[model] };
        objModel[propertyName] = newValue;

        this.setState({
            [model]: objModel,
        });
    }

    private validCropYield() {
        return (
            this.state.insurance.cropGuid ||
            this.state.insurance.cropPurposeGuid ||
            this.state.insurance.yieldUnitGuid ||
            this.state.insurance.averageYield ||
            this.state.insurance.countyTYield
        );
    }

    private addCropYield() {
        const newRecord = { ...this.state.insurance };
        newRecord.cropName = this.getLabelByValue(
            this.cropLocalOptions,
            this.state.insurance.cropGuid
        );
        newRecord.cropPurposeName = this.getLabelByValue(
            this.cropPurposeLocalOptions,
            this.state.insurance.cropPurposeGuid
        );
        newRecord.yieldUnitName = this.getLabelByValue(
            this.yieldUnitListOptions,
            this.state.insurance.yieldUnitGuid
        );

        const newCropYieldList = [...this.props.activeField.fieldCropYieldList, newRecord];

        this.cropLocalOptions = this.clearSelection(this.cropLocalOptions);
        this.cropPurposeLocalOptions = this.clearSelection(this.cropPurposeLocalOptions);
        this.yieldUnitListOptions = this.clearSelection(this.yieldUnitListOptions);
        this.updateFieldModel("fieldCropYieldList", newCropYieldList);

        this.setState({
            insurance: {
                cropGuid: "",
                cropName: "",
                cropPurposeGuid: "",
                cropPurposeName: "",
                yieldUnitGuid: "",
                yieldUnitName: "",
                countyTYield: null,
                averageYield: null,
                calculatedYield: null,
                fieldGuid: "",
                fieldCropYieldGuid: "",
            },
        });
    }

    private removeCropYield(key: any) {
        const fieldCropYieldList = [
            ...this.props.activeField.fieldCropYieldList?.slice(0, key),
            ...this.props.activeField.fieldCropYieldList?.slice(key + 1),
        ];
        this.updateFieldModel("fieldCropYieldList", fieldCropYieldList);
    }

    private validFieldLease() {
        return (
            this.state.lease.landlordName ||
            this.state.lease.cropSharePercent ||
            this.state.lease.parentProductTypeGuid ||
            this.state.lease.productTypeSharePercent
        );
    }

    private addFieldLease() {
        const newRecord = { ...this.state.lease };
        newRecord.parentProductTypeName = this.getLabelByValue(
            this.parentProductTypeListOptions,
            this.state.lease.parentProductTypeGuid
        );
        const newLeaseList = [...this.props.activeField.fieldLeaseList, newRecord];
        this.parentProductTypeListOptions = this.clearSelection(this.parentProductTypeListOptions);
        this.updateFieldModel("fieldLeaseList", newLeaseList);

        this.setState({
            lease: {
                landlordName: "",
                cropSharePercent: null,
                parentProductTypeGuid: "",
                parentProductTypeName: "",
                productTypeSharePercent: null,
                fieldGuid: "",
                fieldLeaseGuid: "",
            },
        });
    }

    private removeFieldLease(key: any) {
        const fieldLeaseList = [
            ...this.props.activeField.fieldLeaseList?.slice(0, key),
            ...this.props.activeField.fieldLeaseList?.slice(key + 1),
        ];

        this.updateFieldModel("fieldLeaseList", fieldLeaseList);
    }

    private onAgvanceCustomerChange(guid: string) {
        const selectedItem = this.props.agvanceOrgList.filter((record) => {
            return record.agvanceCustomerGuid === guid;
        });
        let modifiedFieldModel = {
            ...this.props.activeField,
        };
        if (selectedItem.length) {
            this.setState({
                agvanceCustomer: guid,
            });

            if (this.props.activeField.fieldGuid === "") {
                modifiedFieldModel = {
                    ...this.props.activeField,
                    fieldName: "",
                    farmName: "",
                };
            }
            this.updateFieldForAgvanceCustomers(selectedItem[0], modifiedFieldModel);
        }
    }

    private onFieldNameSelection(
        fieldName = "",
        agvanceFieldGuid = "",
        agvanceFieldId = "",
        farmName = ""
    ) {
        const { updateFieldModel, activeField } = this.props;
        const newFieldModel = {
            ...activeField,
            fieldName,
            agvanceFieldGuid: agvanceFieldGuid || activeField.agvanceFieldGuid,
            agvanceFieldId: agvanceFieldId || activeField.agvanceFieldId,
            farmName,
        };
        updateFieldModel(newFieldModel);
    }

    private getAgvanceCustomer(agvanceOrgList: any[], activeField: any) {
        const selectedValue = activeField.fieldGuid
            ? agvanceOrgList.filter(
                  ({ agvanceCustomerGuid }) =>
                      agvanceCustomerGuid === activeField.agvanceCustomerGuid
              )
            : agvanceOrgList.filter(({ isPrimary }) => isPrimary);
        if (selectedValue.length) {
            this.updateFieldForAgvanceCustomers(selectedValue[0], activeField);
            return selectedValue[0].agvanceCustomerGuid;
        }
        return null;
    }

    /* duplicated in combine-fields-modal.jsx */
    private showAdditionalAgvanceCustomer(nextProps?: IProps) {
        const { agvanceOrgList } = nextProps || this.props;
        // Check for Agvance personality and availability of more than one Agvance customers
        return this.isAgvanceConnected() && agvanceOrgList.length > 1;
    }

    private showAgvanceFieldClassifications(props: IProps = this.props) {
        const { agvanceFieldClassificationList } = props;
        return this.isAgvanceConnected() && agvanceFieldClassificationList.length > 0;
    }

    private isAgvanceConnected() {
        return AgvanceUtils.isAgvanceConnected(this.props.personalityId);
    }

    private getAgvanceFieldList() {
        if (!this.state.agvanceCustomer) {
            return this.props.agvanceFieldList;
        }
        return this.props.agvanceFieldList.filter(({ agvanceGrowerGuid }) => {
            return this.state.agvanceCustomer === agvanceGrowerGuid;
        });
    }

    private closeCertOrganicVerifyModal() {
        this.setState({ showCertOrganicChangeDialog: false });
    }

    #filterAgvanceFieldClassifications(classifications: number[]): IAgvClassification[] {
        return this.props.agvanceFieldClassificationList.filter((c) =>
            classifications?.some((k) => c.classificationKey === k)
        );
    }

    private onBillableAcreChangeDebounce = AppHelpers.debounce(() => {
        logFirebaseEvent("field_setup_agvance_billable_area");
    }, 1000);

    /// ------------- ///
    render() {
        const { agvanceFieldAcres } = this.state;
        const { formatMessage, formatNumber } = this.props.intl;
        const { agvanceOrgList, userInfo } = this.props;
        const isFieldNameError = false;
        const isFieldBoundaryError = false;
        let errorCodeList = [];
        const { agvanceCustomer } = this.state;
        if (this.props.apiError && this.props.apiError.errorCodeList) {
            errorCodeList = this.props.apiError.errorCodeList;
        }

        return (
            <div
                className={classNames(
                    "information-detail-container",
                    "field-information-detail-container"
                )}
            >
                <div className={classNames("information-detail", "field-information-detail")}>
                    {isFieldNameError && (
                        <div className="field-name-error-msg">
                            {formatMessage(messages.fieldNameErrorMsg)}
                        </div>
                    )}
                    {isFieldBoundaryError && (
                        <div className="field-boundary-error-msg">
                            {formatMessage(messages.fieldBoundaryErrorMsg)}
                        </div>
                    )}
                    {/* General Information */}
                    <Bucket
                        className="detail-bucket general-info-bucket"
                        showSymbol={false}
                        isCollapsible={false}
                        isExpanded={true}
                    >
                        <BucketHeader className="detail-bucket-header">
                            {formatMessage(messages.generalInfoText)}
                        </BucketHeader>
                        {/* Field Name */}
                        <SearchAgvanceField
                            autoFocus
                            openOnFocus={false}
                            errorCodes={errorCodeList}
                            value={this.props.activeField.fieldName}
                            onInputChange={(v) => this.updateFieldModel("fieldName", v)}
                            onChange={this.onFieldNameSelection.bind(this)}
                            records={this.getAgvanceFieldList()}
                        />
                        {!this.showAdditionalAgvanceCustomer() ? null : (
                            <SelectInput
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                clearFilterInputOnBlur={false}
                                placeholderText={formatMessage(globalMessages.agvanceCustomer)}
                                value={agvanceCustomer}
                                containerClassNames={[
                                    "field-agvance-customer",
                                    {
                                        "form-input-error": errorCodeList.some((item) =>
                                            [
                                                869,
                                                870,
                                                871,
                                                "Agvance.LocationIsRequired",
                                                "Agvance.CustomerIsRequired",
                                                "Agvance.FieldIsRequired",
                                            ].includes(item)
                                        ),
                                    },
                                ]}
                                onChange={(v) => this.onAgvanceCustomerChange(v)}
                                options={agvanceOrgList.map(
                                    ({
                                        agvanceGrowerName,
                                        customerName,
                                        agvanceCustomerGuid,
                                        orgLevelName,
                                        isPrimary,
                                        alternateCustomerIdSelecting,
                                    }) => ({
                                        label: `${
                                            isPrimary ? customerName : agvanceGrowerName
                                        } > ${orgLevelName}`,
                                        titleValue: `${agvanceGrowerName} - ${alternateCustomerIdSelecting}`,
                                        value: agvanceCustomerGuid,
                                    })
                                )}
                                optionRenderer={optionRendererWithCustomTitle}
                                required
                            />
                        )}
                        {/* Farm Name */}
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            clearFilterInputOnBlur={false}
                            options={this.farmListOptions}
                            noOptionsRenderer={null}
                            onInputChange={(v) => this.updateFieldModel("farmName", v)}
                            onChange={(v) => this.updateFieldModel("farmName", v)}
                            placeholderText={formatMessage(messages.fieldFarmNamePlaceholderText)}
                            value={this.props.activeField.farmName}
                            initialFilterStr={this.props.activeField.farmName}
                            maxLength={50}
                            allowEmptyOptions
                        />
                        {/*Certified Organic*/}
                        <Checkbox
                            value={this.props.activeField.certifiedOrganic}
                            label={formatMessage(messages.certifiedOrganicText)}
                            onChange={(e, v) => {
                                this.setState({
                                    newCertOrganicValue: v,
                                    showCertOrganicChangeDialog: true,
                                });
                            }}
                        />
                        {/* Irrigated */}
                        <SelectInput
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            containerClassNames={["irrigation-select"]}
                            options={this.irrigatedListOptions}
                            onChange={(v) => this.updateFieldModel("irrigated", v)}
                            value={this.props.activeField.irrigated}
                            placeholderText={formatMessage(messages.irrigatedPlaceHolderText)}
                        />
                        {/*Drainage Tile*/}
                        <Checkbox
                            value={this.props.activeField.drainageTile}
                            label={formatMessage(messages.drainageTileText)}
                            onChange={(e, v) => {
                                this.updateFieldModel("drainageTile", v);
                            }}
                        />
                        {/*Irrigation Type*/}
                        {!this.props.activeField.irrigated ? null : (
                            <SelectInput
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                containerClassNames={["irrigation-select"]}
                                options={this.irrigationTypeListOptions}
                                onChange={(v) => this.updateFieldModel("irrigationTypeGuid", v)}
                                value={this.props.activeField.irrigationTypeGuid}
                                placeholderText={formatMessage(
                                    messages.irrigationTypePlaceholderText
                                )}
                            />
                        )}
                        {/*Agvance Field Classifications*/}
                        {!this.showAgvanceFieldClassifications() ? null : (
                            <SelectInputMulti
                                key={`classification${this.state.multiSelectIndex}`}
                                isClearable={true}
                                options={this.props.agvanceFieldClassificationList
                                    .map((c) => ({
                                        value: c.classificationKey,
                                        label: c.classificationName,
                                    }))
                                    .sort((a, b) => (b.label > a.label ? -1 : 1))}
                                placeholder={formatMessage(messages.fieldClassificationText)}
                                onChange={(classifications: number[]) => {
                                    logFirebaseEvent("field_field_classifications");
                                    this.updateFieldModel(
                                        "classifications",
                                        this.#filterAgvanceFieldClassifications(classifications)
                                    );
                                }}
                                showTopLabel={true}
                                sort={true}
                                value={this.props.activeField.classifications
                                    ?.map((c: IAgvClassification) => ({
                                        value: c.classificationKey,
                                        label: c.classificationName,
                                    }))
                                    .sort((a, b) => (b.label > a.label ? -1 : 1))}
                            />
                        )}
                        {/*Agvance Billable Area*/}
                        {!(
                            this.isAgvanceConnected() && userInfo?.role?.agvanceBillableArea
                        ) ? null : (
                            <NumericInput
                                onChange={(value) => {
                                    if (value != this.state.agvanceFieldAcres) {
                                        this.onBillableAcreChangeDebounce();
                                    }
                                    this.setState({ agvanceFieldAcres: value });
                                    this.updateFieldModel("agvanceBillableArea", value);
                                }}
                                placeholderText={"Agvance Billable Area"}
                                value={
                                    agvanceFieldAcres != null && agvanceFieldAcres > 0
                                        ? agvanceFieldAcres
                                        : null
                                }
                            />
                        )}
                        {/*Directions*/}
                        <TextArea
                            maxLength={4000}
                            value={
                                this.props.activeField.directions
                                    ? this.props.activeField.directions
                                    : ""
                            }
                            onChange={(v) => {
                                this.updateFieldModel("directions", v);
                            }}
                            placeholderText={formatMessage(messages.directionsPlaceholderText)}
                        />
                        {/*Field Notes*/}
                        <TextArea
                            maxLength={4000}
                            value={
                                this.props.activeField.fieldNotes
                                    ? this.props.activeField.fieldNotes
                                    : ""
                            }
                            onChange={(v) => {
                                this.updateFieldModel("fieldNotes", v);
                            }}
                            placeholderText={formatMessage(messages.fieldNotesPlaceholderText)}
                        />
                    </Bucket>

                    {/* Crop Details */}
                    <Bucket className="detail-bucket crop-detail-bucket">
                        <BucketHeader className="detail-bucket-header">
                            {formatMessage(messages.cropInfoText)}
                        </BucketHeader>
                        <SelectInput
                            options={this.cropListOptions}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.cropPlaceholderText)}
                            onChange={(v) => {
                                this.updateFieldModel("cropGuid", v);
                            }}
                            value={this.props.activeField.cropGuid}
                        />
                        <SelectInput
                            options={this.cropPurposeListOptions}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.cropPurposePlaceholderText)}
                            onChange={(v) => {
                                this.updateFieldModel("cropPurposeGuid", v);
                            }}
                            value={this.props.activeField.cropPurposeGuid}
                        />
                        <SelectInput
                            options={this.brandListOptions}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.brandPlaceholderText)}
                            disabled={
                                !(
                                    this.brandListOptions.length > 0 &&
                                    this.props.activeField.cropGuid
                                )
                            }
                            onChange={(v) => {
                                this.updateFieldModel("brandOrganizationGuid", v);
                            }}
                            value={this.props.activeField.brandOrganizationGuid}
                        />
                        <SelectInput
                            options={this.varietyListOptions}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.varietyPlaceholderText)}
                            disabled={
                                !(this.varietyListOptions.length > 0) ||
                                !this.props.activeField.brandOrganizationGuid
                            }
                            onChange={(v) => {
                                this.updateFieldModel("varietyHybridGuid", v);
                            }}
                            value={this.props.activeField.varietyHybridGuid}
                        />
                        <DateInput
                            openDirection={"left"}
                            value={
                                this.props.activeField.cropPlantingDate
                                    ? moment(this.props.activeField.cropPlantingDate)
                                    : null
                            }
                            onChange={(v) => {
                                this.updateFieldModel("cropPlantingDate", v);
                            }}
                            placeholderText={formatMessage(messages.plantingDatePlaceholderText)}
                        />
                    </Bucket>
                    {/*USDA Information*/}
                    <Bucket className="detail-bucket usda-detail-bucket">
                        <BucketHeader className="detail-bucket-header">
                            {formatMessage(messages.usdaInfoText)}
                        </BucketHeader>
                        {/*Legal Description*/}
                        <TextArea
                            maxLength={4000}
                            value={this.props.activeField.legalDescription}
                            onChange={(v) => {
                                this.updateFieldModel("legalDescription", v);
                            }}
                            placeholderText={formatMessage(
                                messages.legalDescriptionPlaceholderText
                            )}
                        />
                        {/*FSA (Acres)*/}
                        <NumericInput
                            scale={2}
                            precision={12}
                            value={this.props.activeField.fsaAcres}
                            onChange={(v) => {
                                this.updateFieldModel("fsaAcres", v);
                            }}
                            placeholderText={formatMessage(messages.fsaAcresPlaceholderText)}
                        />
                        {/*CLU acres*/}
                        <NumericInput
                            scale={2}
                            precision={12}
                            value={this.props.activeField.cluAcres}
                            onChange={(v) => {
                                this.updateFieldModel("cluAcres", v);
                            }}
                            placeholderText={formatMessage(messages.cluAcresPlaceholderText)}
                        />

                        {this.props.editFieldGuid && (
                            <div className="bucket-content">
                                {/*State*/}
                                <TextInput
                                    maxLength={2}
                                    value={this.props.activeField.fieldBoundary.stateAbbr}
                                    placeholderText={formatMessage(messages.statePlaceholderText)}
                                    disabled={true}
                                />
                                {/*County*/}
                                <TextInput
                                    maxLength={50}
                                    value={this.props.activeField.fieldBoundary.county}
                                    placeholderText={formatMessage(messages.countyPlaceholderText)}
                                    disabled={true}
                                />
                                {/*Township*/}
                                <TextInput
                                    maxLength={9}
                                    value={this.props.activeField.fieldBoundary.township}
                                    placeholderText={formatMessage(
                                        messages.townshipPlaceholderText
                                    )}
                                    disabled={true}
                                />
                                {/*Range*/}
                                <TextInput
                                    maxLength={9}
                                    value={this.props.activeField.fieldBoundary.range}
                                    placeholderText={formatMessage(messages.rangePlaceholderText)}
                                    disabled={true}
                                />
                                {/*Section*/}
                                <TextInput
                                    maxLength={3}
                                    value={this.props.activeField.fieldBoundary.section}
                                    placeholderText={formatMessage(messages.sectionPlaceholderText)}
                                    disabled={true}
                                />
                                {/*APN*/}
                                <TextInput
                                    maxLength={20}
                                    value={this.props.activeField.apn}
                                    onChange={(v) => {
                                        this.updateFieldModel("apn", v);
                                    }}
                                    placeholderText={formatMessage(messages.apnPlaceholderText)}
                                />
                            </div>
                        )}

                        {/*CLU ID*/}
                        <NumericInput
                            scale={0}
                            precision={9}
                            value={this.props.activeField.cluId}
                            suppressFormatting={true}
                            onChange={(v) => {
                                this.updateFieldModel("cluId", v);
                            }}
                            placeholderText={formatMessage(messages.cluIdPlaceholderText)}
                        />
                        {/*FSA Farm Number*/}
                        <NumericInput
                            scale={0}
                            precision={9}
                            value={this.props.activeField.fsaFarmNum}
                            onChange={(v) => {
                                this.updateFieldModel("fsaFarmNum", v);
                            }}
                            placeholderText={formatMessage(messages.fsaFarmNumberPlaceholderText)}
                            suppressFormatting={true}
                        />
                        {/*FSA Field Number*/}
                        <NumericInput
                            scale={0}
                            precision={9}
                            value={this.props.activeField.fsaFieldNum}
                            onChange={(v) => {
                                this.updateFieldModel("fsaFieldNum", v);
                            }}
                            placeholderText={formatMessage(messages.fsaFieldNumberPlaceholderText)}
                            suppressFormatting={true}
                        />
                        {/*FSA Tract Number*/}
                        <NumericInput
                            scale={0}
                            precision={9}
                            value={this.props.activeField.fsaTractNum}
                            onChange={(v) => {
                                this.updateFieldModel("fsaTractNum", v);
                            }}
                            placeholderText={formatMessage(messages.fsaTractNumberPlaceholderText)}
                            suppressFormatting={true}
                        />

                        {/*Watershed Name*/}
                        <TextInput
                            maxLength={50}
                            value={this.props.activeField.fieldBoundary.watershedName}
                            onChange={(v) => {
                                this.updateFieldModel("fieldBoundary.watershedName", v);
                            }}
                            placeholderText={formatMessage(messages.watershedNamePlaceholderText)}
                        />
                        {/*Watershed Number*/}
                        <TextInput
                            maxLength={12}
                            value={this.props.activeField.fieldBoundary.watershedNum}
                            onChange={(v) => {
                                this.updateFieldModel("fieldBoundary.watershedNum", v);
                            }}
                            placeholderText={formatMessage(messages.watershedNumberPlaceholderText)}
                        />

                        <div className="bucket-content">
                            {/*Dominant Soil Series*/}
                            <TextInput
                                value={this.props.activeField.fieldBoundary.soilSeries}
                                placeholderText={formatMessage(
                                    messages.dominantSoilSeriesPlaceholderText
                                )}
                                disabled={true}
                            />
                            {/*Dominant Soil Texture*/}
                            <TextInput
                                value={this.props.activeField.fieldBoundary.soilTexture}
                                placeholderText={formatMessage(
                                    messages.dominantSoilTexturePlaceholderText
                                )}
                                disabled={true}
                            />
                        </div>
                    </Bucket>
                    {/* Insurance Information */}
                    <Bucket className="detail-bucket insurance-detail-bucket">
                        <BucketHeader
                            className={classNames("detail-bucket-header", {
                                "label-error": errorCodeList.some((item) =>
                                    [
                                        697,
                                        742,
                                        "Field.FieldYieldUnitRequired",
                                        "Field.FieldYieldUnitDependentRequired",
                                    ].includes(item)
                                ),
                            })}
                        >
                            {formatMessage(messages.insuranceText)}
                        </BucketHeader>
                        {/*Insurance Policy Number*/}
                        <TextInput
                            maxLength={20}
                            value={this.props.activeField.insuranceNum}
                            onChange={(v) => {
                                this.updateFieldModel("insuranceNum", v);
                            }}
                            placeholderText={formatMessage(
                                messages.insurancePolicyNumPlaceholderText
                            )}
                        />
                        {/*Insurance Policy type*/}
                        <SelectInput
                            options={this.insurancePolicyTypeListOptions}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(
                                messages.insurancePolicyTypePlaceholderText
                            )}
                            onChange={(v) => {
                                this.updateFieldModel("insurancePolicyTypeGuid", v);
                            }}
                            value={this.props.activeField.insurancePolicyTypeGuid}
                        />
                        {/*High-Risk Land (TF)*/}
                        <div className="high-risk-land">
                            <Checkbox
                                value={this.props.activeField.highRiskLandYn}
                                label={formatMessage(messages.highRiskLandText)}
                                onChange={(e, v) => {
                                    this.updateFieldModel("highRiskLandYn", v);
                                }}
                            />
                        </div>
                        {/*Insurance Information Per Crop*/}
                        <div className="detail-header">
                            {formatMessage(messages.insuraceInfoByCropLabel)}
                        </div>
                        <div className="insurance-per-crop">
                            {/* Crop */}
                            <SelectInput
                                options={this.cropLocalOptions}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                placeholderText={formatMessage(messages.cropPlaceholderText)}
                                onChange={(v) => {
                                    this.updateLocalModel("insurance", "cropGuid", v);
                                }}
                                value={this.state.insurance.cropGuid}
                            />
                            {/* Crop Purpose */}
                            <SelectInput
                                options={this.cropPurposeLocalOptions}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                placeholderText={formatMessage(messages.cropPurposePlaceholderText)}
                                onChange={(v) => {
                                    this.updateLocalModel("insurance", "cropPurposeGuid", v);
                                }}
                                value={this.state.insurance.cropPurposeGuid}
                            />

                            {/*Crop Yield Unit*/}
                            <SelectInput
                                options={this.yieldUnitListOptions}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                placeholderText={formatMessage(messages.yieldUnitPlaceholderText)}
                                onChange={(v) => {
                                    this.updateLocalModel("insurance", "yieldUnitGuid", v);
                                }}
                                value={this.state.insurance.yieldUnitGuid}
                            />

                            {/*County T-Yield*/}
                            <NumericInput
                                scale={2}
                                precision={12}
                                value={this.state.insurance.countyTYield}
                                onChange={(v) => {
                                    this.updateLocalModel("insurance", "countyTYield", v);
                                }}
                                placeholderText={formatMessage(
                                    messages.countyTYieldPlaceholderText
                                )}
                            />

                            {/* Average Yield */}
                            <NumericInput
                                scale={2}
                                precision={12}
                                value={this.state.insurance.averageYield}
                                onChange={(v) => {
                                    this.updateLocalModel("insurance", "averageYield", v);
                                }}
                                placeholderText={formatMessage(
                                    messages.averageYieldPlaceholderText
                                )}
                            />

                            <NoLink
                                className={classNames(
                                    "listAddNoLink",
                                    !this.validCropYield() ? "disabled" : null
                                )}
                                label={formatMessage(messages.addCropYieldText)}
                                onClick={() => {
                                    if (this.validCropYield()) {
                                        this.addCropYield();
                                    }
                                }}
                            />

                            {this.props.activeField.fieldCropYieldList?.length > 0 &&
                                this.props.activeField.fieldCropYieldList?.map(
                                    (cropYield, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="detail-n-list field-crop-yield-list"
                                            >
                                                <div
                                                    className="trashcan-icon-container"
                                                    onClick={() => {
                                                        this.removeCropYield(index);
                                                    }}
                                                >
                                                    <TrashcanIcon className="trashcan-icon" />
                                                </div>
                                                <div className="data-container">
                                                    <div className="first-row">
                                                        {cropYield.cropName}
                                                        {cropYield.cropName &&
                                                            (cropYield.cropPurposeName ||
                                                                cropYield.yieldUnitName) &&
                                                            " - "}
                                                        {cropYield.cropPurposeName}
                                                        {cropYield.cropPurposeName &&
                                                            cropYield.yieldUnitName &&
                                                            " - "}
                                                        {cropYield.yieldUnitName}
                                                    </div>
                                                    <div className="second-row">
                                                        {cropYield.countyTYield}
                                                        {cropYield.countyTYield &&
                                                            (cropYield.averageYield ||
                                                                cropYield.calculatedYield) &&
                                                            " - "}
                                                        {cropYield.averageYield}
                                                        {cropYield.averageYield &&
                                                            cropYield.calculatedYield &&
                                                            " - "}
                                                        {cropYield.calculatedYield}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </Bucket>

                    {/*LEASED INFO*/}
                    <Bucket className="detail-bucket lease-detail-bucket">
                        <BucketHeader className="detail-bucket-header">
                            {formatMessage(messages.leaseSplitText)}
                        </BucketHeader>
                        {/*Lease Type*/}
                        <SelectInput
                            options={this.leaseTypeListOptions}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(messages.leaseTypePlaceholderText)}
                            onChange={(v) => {
                                this.updateFieldModel("landOwnershipTypeGuid", v);
                            }}
                            value={this.props.activeField.landOwnershipTypeGuid}
                        />

                        {/*Lease Expire Date (date)*/}
                        <DateInput
                            openDirection="right"
                            value={
                                this.props.activeField.leaseExpireDate
                                    ? moment(this.props.activeField.leaseExpireDate)
                                    : null
                            }
                            onChange={(v) => {
                                this.updateFieldModel("leaseExpireDate", v);
                            }}
                            placeholderText={formatMessage(messages.leaseExpireDatePlaceholderText)}
                        />

                        {/*LEASED SHARE INFO*/}
                        <div className="detail-header">
                            {formatMessage(messages.billingSplitsInfoLabel)}
                        </div>

                        {/*Landlord Name*/}
                        <TextInput
                            maxLength={40}
                            value={this.state.lease.landlordName}
                            onChange={(v) => {
                                this.updateLocalModel("lease", "landlordName", v);
                            }}
                            placeholderText={formatMessage(messages.landlordNamePlaceholderText)}
                        />

                        {/*Crop Share Percent (%)*/}
                        <NumericInput
                            scale={2}
                            precision={5}
                            value={this.state.lease.cropSharePercent}
                            onChange={(v) => {
                                this.updateLocalModel("lease", "cropSharePercent", v);
                            }}
                            placeholderText={formatMessage(
                                messages.cropSharePercentPlaceholderText
                            )}
                        />

                        {/*Share Product Type*/}
                        <SelectInput
                            options={this.parentProductTypeListOptions}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            placeholderText={formatMessage(
                                messages.shareProductTypePlaceholderText
                            )}
                            onChange={(v) => {
                                this.updateLocalModel("lease", "parentProductTypeGuid", v);
                            }}
                            value={this.state.lease.parentProductTypeGuid}
                        />

                        {/*Percent Share (%) of Product Type*/}
                        <NumericInput
                            scale={2}
                            precision={5}
                            value={this.state.lease.productTypeSharePercent}
                            onChange={(v) => {
                                this.updateLocalModel("lease", "productTypeSharePercent", v);
                            }}
                            placeholderText={formatMessage(
                                messages.sharePercentOfProductTypePlaceholderText
                            )}
                        />

                        <NoLink
                            className={classNames(
                                "listAddNoLink",
                                !this.validFieldLease() ? "disabled" : null
                            )}
                            label={formatMessage(messages.addFieldLeaseText)}
                            onClick={() => {
                                if (this.validFieldLease()) {
                                    this.addFieldLease();
                                }
                            }}
                        />
                        {this.props.activeField.fieldLeaseList?.length > 0 &&
                            this.props.activeField.fieldLeaseList?.map((lease, index) => {
                                return (
                                    <div key={index} className="detail-n-list field-lease-list">
                                        <div
                                            className="trashcan-icon-container"
                                            onClick={() => {
                                                this.removeFieldLease(index);
                                            }}
                                        >
                                            <TrashcanIcon className="trashcan-icon" />
                                        </div>
                                        <div className="data-container">
                                            <div className="first-row">
                                                {lease.landlordName}
                                                {lease.landlordName && " - "}
                                                {formatNumber(
                                                    !lease.cropSharePercent
                                                        ? 0
                                                        : lease.cropSharePercent / 100,
                                                    {
                                                        style: "percent",
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </div>
                                            <div className="second-row">
                                                {lease.parentProductTypeName}
                                                {lease.parentProductTypeName && " - "}
                                                {formatNumber(
                                                    !lease.productTypeSharePercent
                                                        ? 0
                                                        : lease.productTypeSharePercent / 100,
                                                    {
                                                        style: "percent",
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </Bucket>
                </div>
                <DialogBox
                    isOpen={this.state.showCertOrganicChangeDialog}
                    onAction={() => {
                        this.updateFieldModel("certifiedOrganic", this.state.newCertOrganicValue);
                        this.closeCertOrganicVerifyModal();
                    }}
                    onClose={() => this.closeCertOrganicVerifyModal()}
                    footerType={DialogBoxFooterType.YES_NO}
                    title={formatMessage(messages.certOrganicVerifyTitle)}
                >
                    {formatMessage(messages.certOrganicVerifyMessage)}
                </DialogBox>
                <FieldBoundaryTransfer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const picklistData = picklistSelectors.getPicklistState(state);
    const unitData = unitSelectors.getUnitsState(state);
    const dropdowns = getFieldModuleDropdowns(state);

    let pickListData = {},
        unitTypeData,
        dropDownData = {};
    for (const key of Object.keys(pickLists)) {
        pickListData = Object.assign(pickListData, {
            [key]: picklistData[pickLists[key]],
        });
    }
    for (const key of Object.keys(units)) {
        unitTypeData = Object.assign(unitData, { [key]: unitData[units[key]] });
    }
    if (dropdowns) {
        for (const key of Object.keys(dropdowns)) {
            dropDownData = Object.assign(dropDownData, {
                [key]: dropdowns[key],
            });
        }
    }

    return {
        ...pickListData,
        ...unitTypeData,
        ...dropDownData,
        agvanceFieldData: getAgvanceFieldData(state),
        agvanceFieldList: getAgvanceFieldList(state),
        agvanceOrgList: getAgvanceOrgLevelList(state),
        agvanceFieldClassificationList: getAgvanceFieldClassificationList(state),
        errorCodes: getErrorCodes(state),
        fetchingFieldInfo: getFetchingFieldInfo(state),
        personalityId: getTheUserPersonalityId(state),
        userInfo: getUser(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        refreshDropdowns: (dropdowns) => {
            dispatch(fetchDropdownData({ ...dropdowns, action: fetchedDropdownData }));
        },
        fetchAgvanceFieldList: (payload) => dispatch(actions.fetchAgvanceFieldList(payload)),
        fetchAgvanceFieldClassificationList: (payload) =>
            dispatch(actions.fetchAgvanceFieldClassificationList(payload)),
        fetchAgvanceOrgLevelList: (payload) => dispatch(actions.fetchAgvanceOrgLevelList(payload)),
        clearAgvanceFieldList: () => dispatch(actions.clearAgvanceFieldList()),
        clearAgvanceFieldClassificationList: () =>
            dispatch(actions.clearAgvanceFieldClassificationList()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(withApiResult(FieldInformationDetail)));
