import { defineMessages } from "react-intl";

export const messages = defineMessages({
    addSelectLbl: { id: "mapTools.addSelectLbl", defaultMessage: "Add/Select" },
    areaTabLbl: { id: "mapTools.areaTabLbl", defaultMessage: "Area" },
    areaUnitLbl: { id: "mapTools.areaUnitLbl", defaultMessage: "Area Unit" },
    batchEvent: { id: "mapTools.batchEvent", defaultMessage: "Batch Event" },
    bufferAddLbl: { id: "mapTools.bufferAddLbl", defaultMessage: "Add Buffer" },
    bufferDistanceLbl: {
        id: "mapTools.bufferDistanceLbl",
        defaultMessage: "Distance",
    },
    bufferErrorTitle: {
        id: "mapTools.bufferErrorTitle",
        defaultMessage: "Zone Buffer Error",
    },
    bufferLbl: { id: "mapTools.bufferLbl", defaultMessage: "Buffer" },
    bufferSetupLbl: {
        id: "mapTools.bufferSetupLbl",
        defaultMessage: "Buffer Setup",
    },
    bufferSourceBoundaryDesc: {
        id: "mapTools.bufferSourceBoundaryDesc",
        defaultMessage:
            "A Buffer will be added based on the distance from the Field's external boundary.",
    },
    bufferSourceBoundaryLbl: {
        id: "mapTools.bufferSourceBoundaryLbl",
        defaultMessage: "Field Boundary",
    },
    bufferSourceFeatureDesc: {
        id: "mapTools.bufferSourceFeatureDesc",
        defaultMessage: "Select a Non-Field Feature to set where to measure Buffer distance from.",
    },
    bufferSourceFeatureLbl: {
        id: "mapTools.bufferSourceFeatureLbl",
        defaultMessage: "Non-Field Feature",
    },
    bufferSourceGeometriesNoIntersection: {
        id: "mapTools.bufferSourceGeometriesNoIntersection",
        defaultMessage: "A buffer cannot be created for this field based on the Distance entered.",
    },
    bufferSourceGeometriesNull: {
        id: "mapTools.bufferSourceGeometriesNull",
        defaultMessage: "There are no features to buffer.",
    },
    bufferSourceLbl: {
        id: "mapTools.bufferSourceLbl",
        defaultMessage: "Source:",
    },
    bufferSourceMeasureDesc: {
        id: "mapTools.bufferSourceMeasureDesc",
        defaultMessage:
            "Use the Measure Tool to create an Area, Line, or Lat/Long to set where to measure the Buffer distance from.",
    },
    bufferSourceMeasureLbl: {
        id: "mapTools.bufferSourceMeasureLbl",
        defaultMessage: "Measure Tool",
    },
    bufferUnitLbl: { id: "mapTools.bufferUnitLbl", defaultMessage: "Unit" },
    bufferUnsupportedUnit: {
        id: "mapTools.bufferUnsupportedUnit",
        defaultMessage: "Unsupported unit: {unit} is not a valid unit.",
    },
    classifyLbl: { id: "mapTools.classifyLbl", defaultMessage: "Classify" },
    clearMeasurementsLbl: {
        id: "mapTools.clearMeasurementsLbl",
        defaultMessage: "Clear Measurements",
    },
    compositeLbl: { id: "mapTools.compositeLbl", defaultMessage: "Composite" },
    confirmDeleteMsg: {
        id: "mapTools.confirmDeleteMsg",
        defaultMessage: "Are you sure you want to delete?",
    },
    confirmDeleteTitle: {
        id: "mapTools.confirmDeleteTitle",
        defaultMessage: "Confirm",
    },
    coordinateFormatLbl: {
        id: "mapTools.coordinateFormatLbl",
        defaultMessage: "Coordinate Format",
    },
    clu: { id: "mapTools.clu", defaultMessage: "CLU" },
    cluBoundary: { id: "mapTools.cluBoundary", defaultMessage: "CLU Boundary" },
    createLbl: { id: "mapTools.createLbl", defaultMessage: "Create" },
    dateLbl: { id: "mapTools.loadZones.dateLbl", defaultMessage: "Date" },
    degreesMinutesSecondsOption: {
        id: "mapTools.degreesMinutesSecondsOption",
        defaultMessage: "DMS",
    },
    degreesOption: { id: "mapTools.degreesOption", defaultMessage: "DD" },
    deleteLbl: { id: "mapTools.deleteLbl", defaultMessage: "Delete" },
    deleteVertexLbl: {
        id: "mapTools.deleteVertexLbl",
        defaultMessage: "Delete Vertex",
    },
    drawCircleLbl: { id: "mapTools.drawCircleLbl", defaultMessage: "Circle" },
    drawClipLbl: { id: "mapTools.drawClipLbl", defaultMessage: "Clip" },
    drawNudgeLbl: { id: "mapTools.drawNudgeLbl", defaultMessage: "Nudge" },
    drawPolygonLbl: {
        id: "mapTools.drawPolygonLbl",
        defaultMessage: "Polygon",
    },
    drawPolylineLbl: {
        id: "mapTools.drawPolylineLbl",
        defaultMessage: "Line",
    },
    drawPointLbl: { id: "mapTools.drawPointLbl", defaultMessage: "Point" },
    drawReshapeLbl: {
        id: "mapTools.drawReshapeLbl",
        defaultMessage: "Reshape",
    },
    drawSplitLbl: { id: "mapTools.drawSplitLbl", defaultMessage: "Split" },
    drawToolsLbl: { id: "mapTools.drawToolsLbl", defaultMessage: "Draw" },
    drawUnionLbl: { id: "mapTools.drawUnionLbl", defaultMessage: "Union" },
    editLbl: { id: "mapTools.editLbl", defaultMessage: "Edit" },
    errorCreatingHole: {
        id: "mapTools.errorCreatingHole",
        defaultMessage: "Unable to clip boundary to get a valid result. No action taken.",
    },
    errorEntireFieldZoneUndefined: {
        id: "mapTools.errorEntireFieldZoneUndefined",
        defaultMessage: "Expected entireFieldZones to be set but it is undefined.",
    },
    errorModifyingGeometry: {
        id: "mapTools.errorModifyingGeometry",
        defaultMessage: "Error occurred while modifying geometries",
    },
    errorUnableToUnion: {
        id: "mapTools.errorUnableToUnion",
        defaultMessage:
            "Unable to union the selected polygon parts. Please select " +
            "another part that shares a border with the currently selected part.",
    },
    errorUploadingFile: {
        id: "mapTools.errorUploadingFile",
        defaultMessage: "Error Uploading File.",
    },
    errorUploadingFileServerError: {
        id: "mapTools.errorUploadingFileServerError",
        defaultMessage: "Unknown server error.",
    },
    explodeLbl: { id: "mapTools.explodeLbl", defaultMessage: "Explode" },
    extentFullLbl: { id: "mapTools.extentFullLbl", defaultMessage: "Full" },
    extentLbl: { id: "mapTools.extentLbl", defaultMessage: "Extent" },
    extentNextLbl: { id: "mapTools.extentNextLbl", defaultMessage: "Next" },
    extentPreviousLbl: {
        id: "mapTools.extentPreviousLbl",
        defaultMessage: "Previous",
    },
    eventIdSearchLbl: {
        id: "mapTools.eventIdSearchLbl",
        defaultMessage: "Find Event ID",
    },
    fieldBoundaryTransfer: {
        id: "mapTools.fieldBoundaryTransfer",
        defaultMessage: "Transfer",
    },
    importLbl: { id: "mapTools.importLbl", defaultMessage: "Import" },
    importZoneNoneIntersectField: {
        id: "mapTools.importZoneNoneIntersectField",
        defaultMessage:
            "The imported file is not spatially located within this field boundary so this is not a valid import.",
    },
    importZoneSomeDoNotIntersectField: {
        id: "mapTools.importZoneSomeDoNotIntersectField",
        defaultMessage:
            "{count} {count, plural, one {zone} other {zones}} in the imported file {count, plural, one {does} other {do}} not intersect this field boundary and {count, plural, one {has} other {have}} been skipped.",
    },
    importZoneInvalidImportTitle: {
        id: "mapTools.importZoneInvalidImportTitle",
        defaultMessage: "Invalid Import File",
    },
    invalidBatchEditMsg: {
        id: "mapTools.invalidBatchEditMsg",
        defaultMessage: "This action is not allowed since this Event was created as a batch",
    },
    invalidEdit: { id: "mapTools.invalidEdit", defaultMessage: "Invalid Edit" },
    invalidFiles: {
        id: "mapTools.invalidFiles",
        defaultMessage:
            "Invalid files.  We are expecting 3-4 files all with the same name and the " +
            "following extensions: .shp, .shx, .dbf and .prj (where the .prj file is optional).  Or " +
            "a single zip file containing the 3-4 expected files.",
    },
    invalidValueErrorMsg: {
        id: "mapTools.invalidValueErrorMsg",
        defaultMessage: "Valid range: {low} to {high}",
    },
    invalidZoneSamplingSiteMsg: {
        id: "mapTools.invalidZoneSamplingMsg",
        defaultMessage:
            "Sampling Sites with different Sample IDs are not allowed in the same zone class when Zone Sampling is selected.",
    },
    latitudeInputLbl: {
        id: "mapTools.latitudeInputLbl",
        defaultMessage: "Latitude (Y)",
    },
    lengthTabLbl: { id: "mapTools.lengthTabLbl", defaultMessage: "Line" },
    lengthUnitLbl: {
        id: "mapTools.lengthUnitLbl",
        defaultMessage: "Length Unit",
    },
    loadLbl: { id: "mapTools.loadLbl", defaultMessage: "Load" },
    loadZonesLbl: { id: "mapTools.loadZonesLbl", defaultMessage: "Load Zones" },
    locationTabLbl: {
        id: "mapTools.locationTabLbl",
        defaultMessage: "Lat/Lon",
    },
    longitudeInputLbl: {
        id: "mapTools.longitudeInputLbl",
        defaultMessage: "Longitude (X)",
    },
    measureLbl: { id: "mapTools.measureLbl", defaultMessage: "Measure" },
    nameLbl: { id: "mapTools.loadZones.nameLbl", defaultMessage: "Name" },
    noValueErrorMsg: {
        id: "mapTools.noValueErrorMsg",
        defaultMessage: "Please enter a value.",
    },
    nonFieldFeatureSearchLbl: {
        id: "mapTools.nonFieldFeatureSearchLbl",
        defaultMessage: "Find Non-Field Feature",
    },
    nudgeDirections: {
        id: "mapTools.nudgeDirections",
        defaultMessage: "Click and drag to move",
    },
    numberOfItemsInQueue: {
        id: "mapTools.numberOfItemsInQueue",
        defaultMessage:
            "{count} {type, select, " +
            "Events {Event } " +
            "Exports {Export } " +
            "Fields {Field } " +
            "Imports {Import } " +
            "Recs {Recommendation } " +
            "Reports {Report } " +
            "other {} " +
            "}{count, plural, one {request} other {requests}} remaining ({active} Active, {pending} Pending).",
    },
    geometryContainsAFieldPartWarning: {
        id: "mapTools.geometryContainsAFieldPartWarning",
        defaultMessage:
            "Drawn polygon contains a field part - skipping to prevent duplicate zone creation.",
    },
    geometryIsOutsideFieldWarning: {
        id: "mapTools.geometryIsOutsideFieldWarning",
        defaultMessage:
            "Zone cannot be added because its geometry is completely outside field boundary.",
    },
    polygonWithinWarning: {
        id: "mapTools.polygonWithinWarning",
        defaultMessage:
            "This polygon cannot be created since it's completely within an existing field boundary.",
    },
    promptImportAppendBtnText: {
        id: "mapTools.promptImportAppendBtnText",
        defaultMessage: "Append",
    },
    promptImportClipBtnText: {
        id: "mapTools.promptImportClipBtnText",
        defaultMessage: "Clip",
    },
    promptImportMsg: {
        id: "mapTools.promptImportMsg",
        defaultMessage:
            "Do you want to Replace All, Append to current boundary, or Clip to the imported boundary " +
            "using the imported file? This action can be undone by clicking Undo.",
    },
    promptImportReplaceBtnText: {
        id: "mapTools.promptImportReplaceBtnText",
        defaultMessage: "Replace All",
    },
    promptImportTitle: {
        id: "mapTools.promptImportTitle",
        defaultMessage: "Conflict Detected",
    },
    promptImportZoneClassifyOptionText: {
        id: "mapTools.promptImportZoneClassifyOptionText",
        defaultMessage:
            "This file has {count} polygons within it. " +
            "You can optionally select a column name from this file to classify these polygons.",
    },
    promptImportZoneClassifySelectPlaceholderText: {
        id: "mapTools.promptImportZoneClassifySelectPlaceholderText",
        defaultMessage: "Column Name",
    },
    promptImportZoneInvalidSelectionText: {
        id: "mapTools.promptImportZoneInvalidSelectionText",
        defaultMessage:
            "Error: Field must either be numeric or contain {count} or fewer unique values",
    },
    promptImportZoneNumberToBeCreatedText: {
        id: "mapTools.promptImportZoneNumberToBeCreatedText",
        defaultMessage: "Number of zones to be created: {count}",
    },
    promptImportZoneNoDataText: {
        id: "mapTools.promptImportZoneNoDataText",
        defaultMessage: "Error: Field is empty (no data to classify)",
    },
    promptImportZoneReplaceMsg: {
        id: "mapTools.promptImportZoneReplaceMsg",
        defaultMessage:
            "Do you want to proceed with adding the new zone(s) from the imported file? " +
            "Any existing zones will be replaced. (This action cannot be undone.)",
    },
    promptImportZoneReplaceTitle: {
        id: "mapTools.promptImportZoneReplaceTitle",
        defaultMessage: "Import Zones",
    },
    promptRemoveMinAreaPolysMsg: {
        id: "mapTools.promptRemoveMinAreaPolysMsg",
        defaultMessage:
            "The boundary clip has resulted in one or more polygons that are less than 0.25 " +
            "acres.  Would you like these removed?",
    },
    promptRemoveMinAreaPolysTitle: {
        id: "mapTools.RemoveMinAreaPolysTitle",
        defaultMessage: "Warning",
    },
    promptSimplifyVertexMsg: {
        id: "mapTools.promptSimplifyVertexMsg",
        defaultMessage:
            "The vertex you were about to edit is in close proximity to {count} other " +
            "{count, plural, one {point} other {points}}.  Would you like to condense these points " +
            "into a single point to simplify the editing process?",
    },
    promptSimplifyVertexTitle: {
        id: "mapTools.promptSimplifyVertexTitle",
        defaultMessage: "Clustered Vertices Detected",
    },
    processCountsInfoLbl: {
        id: "mapTools.processCountsInfoLbl",
        defaultMessage:
            "{type, select, " +
            "Events {Events} " +
            "Exports {Exports} " +
            "Fields {Fields} " +
            "Imports {Imports} " +
            "Recs {Recs} " +
            "Layers {Layers} " +
            "Reports {Reports} " +
            "other {Unknown} " +
            "}",
    },
    processCountsLbl: {
        id: "mapTools.processCountsLbl",
        defaultMessage: "In Queue",
    },
    rangeLbl: { id: "mapTools.rangeLbl", defaultMessage: "Range" },
    redoLbl: { id: "mapTools.redoLbl", defaultMessage: "Redo" },
    resetLbl: { id: "mapTools.resetLbl", defaultMessage: "Reset" },
    retry: { id: "mapTools.retry", defaultMessage: "Import with WKT" },
    retryWithWKTNotice: {
        id: "mapTools.retryWithWKTNotice",
        defaultMessage:
            "These issues may be resolved by processing the raw Well Known Text(WKT) of the imported shapefile.",
    },
    samplingLbl: { id: "mapTools.samplingLbl", defaultMessage: "Sampling" },
    searchLbl: { id: "mapTools.searchLbl", defaultMessage: "Search" },
    searchPlaceTabLbl: {
        id: "mapTools.searchPlaceTabLbl",
        defaultMessage: "City/Zip",
    },
    searchPLSSTabLbl: {
        id: "mapTools.searchPLSSTabLbl",
        defaultMessage: "PLSS",
    },
    searchXYTabLbl: {
        id: "mapTools.searchXYTabLbl",
        defaultMessage: "Lat/Lon",
    },
    searchNonFieldLbl: {
        id: "mapTools.searchNonFieldLbl",
        defaultMessage: "Non-Field",
    },
    searchEventIdLbl: {
        id: "mapTools.searchEventIdLbl",
        defaultMessage: "Event ID",
    },
    sectionLbl: { id: "mapTools.sectionLbl", defaultMessage: "Section" },
    selectLbl: { id: "mapTools.selectLbl", defaultMessage: "Select" },
    stateLbl: { id: "mapTools.stateLbl", defaultMessage: "State" },
    topologyError: {
        id: "mapTools.topologyError",
        defaultMessage:
            "Topology Error: {error}. Corrections for these issues may result in polygons that are missing or differ from the original input.",
    },
    topologyErrorBasic: {
        id: "mapTools.topologyErrorBasic",
        defaultMessage: "Topology Error: {error}.",
    },
    townshipLbl: { id: "mapTools.townshipLbl", defaultMessage: "Township" },
    typeLbl: { id: "mapTools.loadZones.typeLbl", defaultMessage: "Type" },
    undoLbl: { id: "mapTools.undoLbl", defaultMessage: "Undo" },
    useUniqueValues: {
        id: "mapTools.useUniqueValues",
        defaultMessage: "Use Unique Values",
    },
    zonesLbl: { id: "mapTools.zonesLbl", defaultMessage: "Zones" },
});
